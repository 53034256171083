import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenterEverything from "../components/CenterEverything"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

export default function ChangePassword() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { currentUser, updatePassword /*, updateEmail*/ } = useAuth();
    const [getError, setError] = useState("");
    const [getLoading, setLoading] = useState(false);
    const [getPasswordConfirm, setPasswordConfirm] = useState("");
    const history = useHistory();
    const [getHide, setHide] = useState(true);

    function hideOnClick() {
        setHide(!getHide);
    }

    function handleOnChange(e) {
        setPasswordConfirm(e.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordConfirmRef.current.value === "") {
            history.push("/");
            return
        }

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match");
        }

        const promises = [];
        setLoading(true);
        setError("");

        //if (emailRef.current.value !== currentUser.email) {
        //  promises.push(updateEmail(emailRef.current.value))
        //}

        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
        }

        Promise.all(promises).then(() => {
                history.push("/profile");
            }
        ).catch((error) => {
                setError("Failed: " + error.message);
            }
        ).finally(() => {
                setLoading(false);
            }
        );
    }

    const disableButton = getLoading || getPasswordConfirm === "";
    var inputType = "text";
    if (getHide === true) {
        inputType = "password";
    }

    return (
        <div className="profile">
            <CenterEverything>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Change Password</h2>
                        {getError && <Alert variant="danger">{getError}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label className="label">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    ref={emailRef}
                                    required
                                    defaultValue={currentUser.email}
                                    readOnly
                                />
                            </Form.Group>
                                <Form.Group id="password">
                                <Form.Label className="label">Password</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type={inputType}
                                        ref={passwordRef}
                                        placeholder="Leave blank to keep the same"
                                    />
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon 
                                        className="mt-2" 
                                        icon={getHide ? faEye : faEyeSlash} 
                                        onClick={hideOnClick}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group id="password-confirm">
                                <Form.Label className="label">Password Confirmation</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type={inputType}
                                        ref={passwordConfirmRef}
                                        placeholder="Leave blank to keep the same"
                                        value={getPasswordConfirm}
                                        onChange={handleOnChange}
                                    />
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon 
                                        className="mt-2" 
                                        icon={getHide ? faEye : faEyeSlash} 
                                        onClick={hideOnClick}
                                    />
                                </div>
                            </Form.Group>
                            <Button disabled={disableButton} className="w-100 mt-3" type="submit">Change</Button>
                            <div className="w-100 text-center mt-2 bottom-text">
                                <Link to="/profile">Cancel</Link>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </CenterEverything>
        </div>
    );
}
