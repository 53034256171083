import React, {createContext, useContext} from "react"
import { ams_cal_array } from "../helpers/lighting_vars.js"
//import { XN } from "../helpers/xn.js"
//import { YN } from "../helpers/yn.js"
//import { ZN } from "../helpers/zn.js"
import { vprimeTable } from "../helpers/vprimeTable.js"
import { vmacTable } from "../helpers/vmacTable.js"
import { smacTable } from "../helpers/smacTable.js"
import { melTable } from "../helpers/melTable.js"
import { vLamdaTable } from "../helpers/vLamdaTable.js"
import { melanopsinTable } from "../helpers/melanopsinTable.js"
import { spectrumColors } from "../helpers/spectrumColors.js"
import { DateTime } from "luxon"
import { create, all } from "mathjs"
import axios from "axios"

const Math = create(all, { });

const HelperContext = createContext();

export function useHelper() {
    return useContext(HelperContext);
}

export function HelperContextProvider({ children }) {
    
    function getDateNow() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    }

    function getClosestIndexOfX(arr, x)
    {
        var ret = -1;
        try {
            if (arr.length === 0)
                return ret;
            if (arr[0] === undefined)
                return ret;
                    
            var dist = 99999999;

            for (var i = 0; i < arr.length; ++i) {
                var delta = Math.abs(arr[i].x - x);
                if (dist > delta) {
                    dist = delta;
                    ret = i;
                }
            }
        }
        catch (err) {
            console.log(err);
        }

        return ret;
    }

    function getIndexOfId(arr, id)
    {
        if (arr.length === 0)
            return -1;
        for (var i = 0; i < arr.length; ++i) {
            if (arr[i].id === id) {
                return i;
            }
        }
        return -1;
    }
    
    function xDataContains(arr, ts) {
        if (arr.length === 0)
            return false;
        for (var i = 0; i < arr.length; ++i) {
            if (arr[i].x === ts) {
                return true;
            }
        }
        return false;
    }

    // ...
    function getIndexOfX(arr, ts, source) {
        var ret = -1;
        try {
            if (arr.length === 0)
                return ret;
            for (var i = 0; i < arr.length; ++i) {
                if (arr[i] === undefined)
                    return ret;
                if (arr[i].x === ts) {
                    return i;
                }
            }
        }
        catch (err) {
            console.log( source, err);
        }
        return ret;
    }

    function arrayContainsTs(arr, ts) {  
        if (arr.length === 0)
            return false;
        for (var i = 0; i < arr.length; ++i) {
            if (arr[i].ts === ts) {
                return true;
            }
        }
        return false;
    }

    function insertInOrder(arry, data) {
        for (var idx = 0; idx < arry.length; ++idx) {
            if (data.x < arry[idx].x) {
                arry.splice(idx, 0, data);
                return;
            }
        }
        arry.push(data);
    }
   
    function get6Vars(ts, f1, f2, f3, f4, f5, f6, f7, f8, clr, nir, X, Y, Z) {
        var lux = 0;
        var cs = 0;
        var eml = 0;
        var cct = 0;
        var spd = 0;

        spd = getSPD(f1, f2, f3, f4, f5, f6, f7, f8, clr, nir); // 621 x 10 
        var spd_adjusted = spd.map(x => x*5) // Multiply by 5 since we downsampled to 5 nm 
        //var aX = Math.multiply(spd, XN);
        //var aY = Math.multiply(spd, YN);
        //var aZ = Math.multiply(spd, ZN);

        var x, y, cla;
        if ((X+Y+Z) !== 0) {
            x = X / (X+Y+Z);
            y = Y / (X+Y+Z);
        } else {
            x = 0; y = 0;
        }

        lux = Y * 683;
            
        if (y !== 0.1858) {
            var A = 437*Math.pow((x-0.332)/(0.1858-y),3);
            var B = 3601*Math.pow((x-0.332)/(0.1858-y),2);
            var C = 6861*((x-0.332)/(0.1858-y));
            var D = 5517;
            cct = A + B + C + D;
        } else {
            cct = 5517;
        }

        var vprime = Math.multiply(spd_adjusted, vprimeTable);
        var vmac = Math.multiply(spd_adjusted, vmacTable);
        var smac = Math.multiply(spd_adjusted, smacTable);
        var melanopsin = Math.multiply(spd_adjusted, melanopsinTable);

        if ((smac-0.2616*vmac)>=0) {
            cla = 1547.9*(melanopsin+0.7*(smac-0.2616*vmac)-3.3*(1-Math.exp(-vprime/6.5215)));
        } else {
            cla = 1547.9*melanopsin;
        }

        cs = 0.7*(1-(1/(1+(cla/355.7)**1.1026)));

        var mel = Math.multiply(spd_adjusted, melTable);
        var vlam = Math.multiply(spd_adjusted, vLamdaTable);

        var mp = (1.218*mel)/vlam;
        eml = lux*mp;

        if (isNaN(lux))
            lux = 0;
        if (isNaN(cs))
            cs = 0;
        if (isNaN(eml))
            eml = 0;
        if (isNaN(cct))
            cct = 0;
        return {
            "lux" : lux,
            "cs" : cs,
            "eml" : eml,
            "cct" : cct,
            "spd" : spd,
            "ts" : ts,
            "calculated": true,
        };
    }

    function get6VarsInit(ts) {
        return {
            "lux" : 0,
            "cs" : 0,
            "eml" : 0,
            "cct" : 0,
            "spd" : [
                0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
            ],
            "ts" : ts,
            "calculated": false,
        };    
    }


    function getSPD(f1, f2, f3, f4, f5, f6, f7, f8, clr, nir) {
        //var spectrum = [f1, f2, f3, f4, f5, f6, f7, f8, clr, nir];
        var spectrum = [f1, f2, f3, f4, f5, f6, f7, f8, clr];
        var spd = Math.multiply(ams_cal_array, spectrum);
        return spd;
    }
    
    function translateId(id) {
        if (id.includes("245DFC21") === true)
        {
            id = id.replace("pupil_245DFC21", "");
            id = id.replace("speck_245DFC21", "");
            id = "Speck " + id.toString();
        }
        else if (id.includes("245DFC22") === true)
        {
            id = id.replace("pupil_245DFC22", "");
            id = id.replace("speck_245DFC22", "");
            id = "Speck Mobile " + id.toString();
        }
        return id;
    }

    function getLocalTimeText(ts) {
        var dt = DateTime.fromSeconds(ts);
        return dt.toFormat("h:mm:ss a");
    }

    function getLocalTimezoneText() {
        return DateTime.local().zoneName.replace("_", " ");
    }

    function getUTCDateText(ts) {
        var dt = DateTime.fromSeconds(ts);
        return dt.toUTC().toFormat("M/d/yyyy, h:mm:ss a");
    }

    function getLocalDateText(ts) {
        var dt = DateTime.fromSeconds(ts);
        return dt.toFormat("M/d/yyyy, h:mm:ss a");
    }

    function getUTCDateTextMilitary(ts) {
        var dt = DateTime.fromSeconds(ts);
        return dt.toUTC().toFormat("M/d/yyyy, HH:mm:ss");
    }

    function getLocalDateTextMilitary(ts) {
        var dt = DateTime.fromSeconds(ts);
        return dt.toFormat("M/d/yyyy, HH:mm:ss");
    }

    function getSpectrumColor(wavelength) {
        wavelength = parseInt(wavelength); // definately make it an int

        if (wavelength < 400) {
            wavelength = 400;
        } else if (wavelength >= 700) {
            wavelength = 700;
        }
        var r = spectrumColors[wavelength - 400].r;
        var g = spectrumColors[wavelength - 400].g;
        var b = spectrumColors[wavelength - 400].b;
        return "rgba(" + r + "," + g + ","+ b + ", 1)"; 
    }


    function zeroSpd() {
        return getSPD(0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    }

    function interpolate(x, x0, x1, y0, y1) {
        return (((y1 - y0)*(x - x0))/(x1 - x0)) + y0;
    }

    let timers = [];
    let timersId = 0;

    function createTimer() {
        timers.push({id: ++timersId, time: DateTime.now().toMillis()});
        return timersId;
    }

    function getTimerIndex(id) {
        for (let i = 0; i < timers.length; i++) {
            if (timers[i].id === id) {
                return i;
            }
        }
        return -1;
    }

    function resetTimer(id) {
        let index = getTimerIndex(id);
        if (id !== -1) {
            timers[index].time = DateTime.now().toMillis();
        }
    }

    function deleteTimer(id) {
        let index = getTimerIndex(id);
        if (id !== -1) {
            let ret = currentTimerTime(id);
            timers.splice(index, 1);
            return ret;
        }
        return 0;
    }

    function currentTimerTime(id) {
        let index = getTimerIndex(id);
        if (id === -1) {
            return 0;
        }
        return DateTime.now().toMillis() - timers[index].time;
    }

    var bannerHeight = 0;

    function setBannerHeight(ht) {
        bannerHeight = ht;
    }

    function getBannerHeight() {
        return bannerHeight;
    }

    var footerHeight = 0;

    function setFooterHeight(ht) {
        footerHeight = ht;
    }

    function getFooterHeight() {
        return footerHeight;
    }

    function getAdminTriggerURL() {
        if (process.env.REACT_APP_DEV_ENV === 'true') {
            return  'https://us-central1-test-blue-iris-labs-326117.cloudfunctions.net/firebase_admin'
        }

        // production server trigger url
        return  'https://us-central1-blue-iris-labs-289016.cloudfunctions.net/firebase_admin'
    }

//    HttpContext.Response.Headers.Add("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
// HttpContext.Response.Headers.Add("Access-Control-Allow-Headers", "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers");
// HttpContext.Response.Headers.Add("Access-Control-Allow-Origin", "localhost:4200");    
    function sendHttpRequest(type, jsondata, resp) {
        if (type === 'post') {
            const config = {headers: {
                //'Access-Control-Allow-Origin': 'localhost:3000',
                //'Access-Control-Allow-Credentials': 'true',
                //'Access-Control-Allow-Methods': 'POST',
                //'Access-Control-Allow-Headers': 'Authorization',
                //'Access-Control-Max-Age': '3600',
            
            },}
            axios.post(getAdminTriggerURL(), jsondata, config).then((rsp) => {resp(rsp);});
        }

       /*
        var https = require('https');
        var strdata = JSON.stringify(jsondata);

        var options = {
            host: 'us-central1-blue-iris-labs-289016.cloudfunctions.net',
            path: '/firebase_admin',
            //This is the only line that is new. `headers` is an object with the headers to request
            headers: {
                "content-type": "application/xml",
            //    'Access-Control-Allow-Origin': '*',
            //    'Access-Control-Allow-Methods': 'POST',
            //    'Access-Control-Allow-Headers': 'Content-Type',
            //    'Access-Control-Max-Age': '3600',
            }
        };
        
        var callback = function(response) {
          var str = '';

          response.on('data', function (chunk) {
            str += chunk;
          });

          response.on('end', function () {
            console.log("sendHttpRequest", str);
            resp(str);
          });
        }
        
        var req = https.request(options, callback);
        req.write(strdata);
        req.end();
        */
    }
    
    function initalMetaArray() {
        return [
            {displayname: 'Name', metaname: 'name', suggestions: [], show: false}, 
            {displayname: 'Project', metaname: 'project', suggestions: [], show: false}, 
            {displayname: 'Address', metaname: 'address', suggestions: [], show: false}, 
            {displayname: 'Building', metaname: 'building', suggestions: [], show: false}, 
            {displayname: 'Room', metaname: 'room', suggestions: [], show: false}, 
            {displayname: 'Cardinal Direction', metaname: 'cardinal_direction', 
                suggestions: [" ", "North","North East", "East", "South East", 
                            "South","South West", "West","North West"], show: false}
        ];
    }

    function initializeMetricsArray() {
        return [{displayname: 'Lux', metricname: 'c_slux', show: false},
                {displayname: 'EML', metricname: 'c_eml', show: false},
                {displayname: 'CS', metricname: 'c_cs', show: false}];
    }

    function initializePowerArray() {
        return [{displayname: 'Volts', powername: 'c_volts', show: false}, 
                {displayname: 'Volts', powername: 'c_soc', show: false}]
    }

    const context = {
        getClosestIndexOfX,
        getIndexOfId,
        xDataContains,
        getIndexOfX,
        arrayContainsTs,
        insertInOrder,
        get6Vars,
        get6VarsInit,
        translateId,
        getLocalTimeText,
        getUTCDateText,
        getLocalDateText,
        getUTCDateTextMilitary,
        getLocalDateTextMilitary,
        getLocalTimezoneText,
        getSpectrumColor,
        getSPD,
        zeroSpd,
        interpolate,
        createTimer,
        resetTimer,
        deleteTimer,
        currentTimerTime,
        getDateNow,
        setBannerHeight,
        getBannerHeight,
        setFooterHeight,
        getFooterHeight,
        sendHttpRequest,
        initalMetaArray,
        initializeMetricsArray
    };

    return (
        <HelperContext.Provider value={context}>
            {children}
        </HelperContext.Provider>
    );
}

export default HelperContext;