import React from "react"
import { Container } from "react-bootstrap"

export default function CenterEverything({children}) {
    return (
        <div>
            <Container className="mt-5 justify-content-center d-flex" >
                <div className="row">
                    { children }
                </div>
            </Container>
        </div>
    );
}