import React, {useState, useRef} from "react"
import DeviceSpectrum from "./DeviceSpectrum"
import DeviceGraph from "./DeviceGraph"
import DevicePageButtons from "./DevicePageButtons"
import { useHelper } from "../contexts/HelperContext"
import "./DevicePage.css"

export default function DevicePageAverage({
    boxsize,
    getGraphView, 
    getTimeDivision, 
    dateStartRef,
    dateEndRef,
    ts,
    getSpdData1,
    getSpdData2,
    getSpdData3,
    getSpdData4,
}) {

    const [csHiddenAttr, setCsHiddenAttr] = useState(false);
    const [luxHiddenAttr, setLuxHiddenAttr] = useState(true);
    const [emlHiddenAttr, setEmlHiddenAttr] = useState(true);
    const [cctHiddenAttr, setCctHiddenAttr] = useState(true);


    const spdDataFilteredRef = useRef([]);

    var box2whRef = useRef({width: 0, height: 0});

    if (getGraphView === 1) { 
        box2whRef.current = {width: 0, height: 0};
    }
    var box3 = {width: 0, height: 0};
    box3.width = boxsize.width;
    box3.height = boxsize.height - box2whRef.current.height;
    //console.log ("boxsize", boxsize.width, boxsize.height);
    //console.log ("box2whRef.current", box2whRef.current.width, box2whRef.current.height);
    //console.log ("box3", box3.width, box3.height);

    const helperCtx = useHelper();

    if (getSpdData1.length + getSpdData2.length + getSpdData3.length + getSpdData4.length > 0) {
        var index;
        var _ts = 0;
        var tmp1 = [];
        var tmp2 = [];
        var tmp3 = [];
        var tmp4 = [];

        // do the filtering for each input
        _ts = dateStartRef.current;
        while (_ts <= dateEndRef.current) {
            // put the current i into the filter
            if (getSpdData1.length > 0) {
                index = helperCtx.getClosestIndexOfX(getSpdData1, _ts);
                tmp1.push(getSpdData1[index]);
            }
            if (getSpdData2.length > 0) {
                index = helperCtx.getClosestIndexOfX(getSpdData2, _ts);
                tmp2.push(getSpdData2[index]);
            }
            if (getSpdData3.length > 0) {
                index = helperCtx.getClosestIndexOfX(getSpdData3, _ts);
                tmp3.push(getSpdData3[index]);
            }
            if (getSpdData4.length > 0) {
                index = helperCtx.getClosestIndexOfX(getSpdData4, _ts);
                tmp4.push(getSpdData4[index]);
            }
            _ts += (getTimeDivision * 60); // increase by the time division
        }
        spdDataFilteredRef.current = blend(tmp1, tmp2, tmp3, tmp4);       
    } else {
        spdDataFilteredRef.current = [];
    }

    function getEpoch() {
        var index = helperCtx.getClosestIndexOfX(spdDataFilteredRef.current, ts);
        if (index !== -1) {
            var epoch = spdDataFilteredRef.current[index].x
            if (epoch !== undefined) {
                return epoch;
            }
        }
        return 0;
    }


    function blend (t1, t2, t3, t4) {
        if (t1.length + t2.length + t3.length + t4.length <= 0)
            return [];

        var length = 0;

        if (t1.length > 0)
            length = t1.length;
        if (t2.length > 0)
            length = t2.length;
        if (t3.length > 0)
            length = t3.length;
        if (t4.length > 0)
            length = t4.length;

        var i = 0;
        var ret = [];

        for (i = 0; i < length; i++) {
            var XX = 0;
            var YY = 0;
            var cct = 0;
            var cs = 0;
            var eml = 0;
            var lux = 0;
            var spd = [];
            var spd1 = undefined;
            var spd2 = undefined;
            var spd3 = undefined;
            var spd4 = undefined;
            var x = 0; // (this is timestamp, will be the same for all of them)
            var count = 0;

            if (t1.length > i) {
                spd1 = t1[i].spd;
                count += 1;
                x = t1[i].x;
            }

            if (t2.length > i) {
                spd2 = t2[i].spd;
                count += 1;
                x = t2[i].x;
            }

            if (t3.length > i) {
                spd3 = t3[i].spd;
                count += 1;
                x = t3[i].x;
            }
            
            if (t4.length > i) {
                spd4 = t4[i].spd;
                count += 1;
                x = t4[i].x;
            }


            // 
            if (getGraphView === 0) {
                if (t1.length > i) {
                    XX += t1[i].XX;
                    YY += t1[i].YY;
                    cct += t1[i].cct;
                    cs += t1[i].cs;
                    eml += t1[i].eml;
                    lux += t1[i].lux;
                }

                if (t2.length > i) {
                    XX += t2[i].XX;
                    YY += t2[i].YY;
                    cct += t2[i].cct;
                    cs += t2[i].cs;
                    eml += t2[i].eml;
                    lux += t2[i].lux;
                }

                if (t3.length > i) {
                    XX += t3[i].XX;
                    YY += t3[i].YY;
                    cct += t3[i].cct;
                    cs += t3[i].cs;
                    eml += t3[i].eml;
                    lux += t3[i].lux;
                }

                if (t4.length > i) {
                    XX += t4[i].XX;
                    YY += t4[i].YY;
                    cct += t4[i].cct;
                    cs += t4[i].cs;
                    eml += t4[i].eml;
                    lux += t4[i].lux;
                }

                XX = XX / count;
                YY = YY / count;
                cct = cct / count;
                cs = cs / count;
                eml = eml / count;
                lux = lux / count;
            }

            if (getGraphView === 1) {
                var j;
                // SPD Average
                if (spd1 !== undefined) {
                    for (j = 0; j < spd1.length; j++) {
                        spd.push(spd1[j]);
                    }
                }
                if (spd2 !== undefined) {
                    if (spd === []) {
                        for (j = 0; j < spd2.length; j++) {
                            spd.push(spd2[j]);
                        }
                    } else {
                        for (j = 0; j < spd2.length; j++) {
                            spd[j] += spd2[j];
                        }
                    }
                }
                if (spd3 !== undefined) {
                    if (spd === []) {
                        for (j = 0; j < spd3.length; j++) {
                            spd.push(spd3[j]);
                        }
                    } else {
                        for (j = 0; j < spd3.length; j++) {
                            spd[j] += spd3[j];
                        }
                    }
                }
                if (spd4 !== undefined) {
                    if (spd === []) {
                        for (j = 0; j < spd4.length; j++) {
                            spd.push(spd4[j]);
                        }
                    } else {
                        for (j = 0; j < spd4.length; j++) {
                            spd[j] += spd4[j];
                        }
                    }
                }

                // do the final average
                for (j = 0; j < spd.length; j++) {
                    spd[j] = spd[j] / count;
                }
            }

            var newObj = {
                XX: XX,
                YY: YY,
                cct: cct,
                cs: cs,
                eml: eml,
                lux: lux,
                spd: spd,
                x: x,
            };
            ret.push(newObj);    
        }
        return ret;
    }

    return (
        <div>            
            {getGraphView === 0 &&
                <div className="box2">
                    <DevicePageButtons
                        ts={getEpoch()} 
                        showGraph={getGraphView}
                        spdData={spdDataFilteredRef.current}
                        luxHidden={luxHiddenAttr} 
                        csHidden={csHiddenAttr} 
                        emlHidden={emlHiddenAttr} 
                        cctHidden={cctHiddenAttr}
                        luxOnClick={()=>{
                            if (getGraphView === 0) {
                                setLuxHiddenAttr(!luxHiddenAttr);
                            }
                        }} 
                        csOnClick={()=>{
                            if (getGraphView === 0) {
                                setCsHiddenAttr(!csHiddenAttr);
                            }
                        }} 
                        emlOnClick={()=>{
                            if (getGraphView === 0) {
                                setEmlHiddenAttr(!emlHiddenAttr);
                            }
                        }} 
                        cctOnClick={()=>{
                            if (getGraphView === 0) {
                                setCctHiddenAttr(!cctHiddenAttr);
                            }
                        }}
                        getBoxSize={box2whRef.current}
                        setBoxSize={(size) => {box2whRef.current = size;}}
                    />
                </div>
            }

            <div className="mt-1 graph speccon">
                {getGraphView ===0 && 
                    <DeviceGraph 
                        ts={getEpoch()} 
                        spdData={spdDataFilteredRef.current}
                        luxHidden={luxHiddenAttr} 
                        csHidden={csHiddenAttr} 
                        emlHidden={emlHiddenAttr} 
                        cctHidden={cctHiddenAttr}
                        luxHiddenOnChange={setLuxHiddenAttr}
                        csHiddenOnChange={setCsHiddenAttr}
                        emlHiddenOnChange={setEmlHiddenAttr}
                        cctHiddenOnChange={setCctHiddenAttr}
                        cbChartArea={()=>{}}
                        useTimebar={false}
                        boxsize={box3}
                    /> 
                }
                {getGraphView === 1 && 
                    <DeviceSpectrum
                        ts={getEpoch()} 
                        spdData={spdDataFilteredRef.current}
                        cbChartArea={()=>{}}
                        useTimebar={false}
                        boxsize={box3}
                    /> 
                }
            </div>
        </div>
    );
}
