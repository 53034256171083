import React, { useEffect, useState } from "react"
//import {useHistory } from "react-router-dom"

import { Card } from "react-bootstrap"
import CenterEverything from "../components/CenterEverything"
import "./ShowLicense.css"
import { useHelper } from "../contexts/HelperContext"
import { useAuth } from "../contexts/AuthContext"
import license_html from "../resources/LL2.BlueIris.SoftwareApplicationLicense.htm";



export default function ShowLicense() {
    const helperCtx = useHelper();
    const { currentUser } = useAuth();
    var html = <></>;
    const [, setLoading] = useState(true);

    function License({width, height}) {
        return <iframe title="License" 
        onLoad= {() => {
            console.log("setLoading(false);");
            setLoading(false);
        }}
        style={{width: width, height: height}} src={license_html}></iframe>;
    }
    


    const [getWindowSize, setWindowSize] = useState({w: window.innerWidth, h: window.innerHeight});
    useEffect(() => {
        setLoading(true);
        function handleResize() {
            setWindowSize({w: window.innerWidth, h: window.innerHeight});
        }
        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    }, []);

    if (currentUser !== null) {
        html = (
            <div className="showlicense">
                <CenterEverything>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">Software Agreement</h2>
                            <License 
                            width={getWindowSize.w - 200}
                            height={getWindowSize.h - 450 - helperCtx.getBannerHeight() - helperCtx.getFooterHeight()} />

                            <div className=" mt-3" style={{justifyContent: 'center'}}>
                            </div>
                        </Card.Body>
                    </Card>
                </CenterEverything>
            </div>
        );    
        
    } else {
        html = (<div></div>);
    }

    return (html);
}