import React, { useContext, useState, useEffect } from "react"
import firebase, { auth } from "../firebase"

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthContextProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [acceptedLicense, setAcceptedLicense] = useState(false);
    const [allowShowLicense, setAllowShowLicense] = useState(false);

    function createAccount(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password);
    }

    function updateDisplayName(displayName) {
        return currentUser.updateProfile({
            displayName: displayName,
        }).then(function() {
            // Update successful.
        }).catch(function(error) {
            // An error happened.
            //alert(error.message);
        });    
    }

    useEffect(() => {
        var unsub2 = null;

        const unsub1 = auth.onAuthStateChanged((user) => {
            setAcceptedLicense(false);
            setAllowShowLicense(false);
            if (unsub2 !== null){
                unsub2();
                unsub2 = null;
            }    
            setCurrentUser(user);
            if (user !== null) {
                unsub2 = firebase.firestore().collection("users").doc(user.uid)
                .onSnapshot((doc) => {
                    setAllowShowLicense(true);
                    if (doc.exists) {
                        const doc_data = doc.data();
                        if (doc_data.hasOwnProperty("accepted_license") === true) {
                            var value = doc_data["accepted_license"];
                            setAcceptedLicense(value);
                        }
                    } else {
                        setAcceptedLicense(false);
                        console.log("doc does not exist");
                    }
                });        
            }
            setLoading(false);
        });
        console.log("subscribing to auth.onAuthStateChanged");
        return (() => {
            if (unsub2 !== null){
                unsub2();
                unsub2 = null;
            }
            unsub1();
            console.log("unsubscribing to auth.onAuthStateChanged");
        });
    }, []);

    const value = {
        currentUser, // useState(x)
        acceptedLicense, // useState(x)
        allowShowLicense, // useState(x)
        setAllowShowLicense, // useState(c)
        createAccount, // f(x)
        login, // f(x)
        logout, // f(x)
        resetPassword, // f(x)
        updateEmail, // f(x)
        updatePassword, // f(x)
        updateDisplayName, // f(x)
    }

    return (
        <AuthContext.Provider value={value}>
        {!loading && children}
        </AuthContext.Provider>
    );
}