import React from "react"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "bootswatch/dist/lumen/bootstrap.min.css"

// components
import PrivateRoute from "./components/PrivateRoute"
import Banner from "./components/Banner"
import Footer from "./components/Footer"

// pages
import Devices from "./pages/Devices"
import Device from "./pages/Device"
import DeviceMulti from "./pages/DeviceMulti"
import Admin from "./pages/Admin"
import ForgotPassword from "./pages/ForgotPassword"
import CreateAccount from "./pages/CreateAccount"
import ChangeDisplayName from "./pages/ChangeDisplayName"
import ChangePassword from "./pages/ChangePassword"
import Profile from "./pages/Profile"
import Login from "./pages/Login"
import TestPage1 from "./pages/TestPage1"
import TestPage2 from "./pages/TestPage2"
import ShowLicense from "./pages/ShowLicense"
import DeviceAverage from "./pages/DeviceAverage"

// contexts
import { AdminContextProvider } from "./contexts/AdminContext"
import { HelperContextProvider } from "./contexts/HelperContext"
import { AuthContextProvider } from "./contexts/AuthContext"


require('dotenv').config();
function App() {
    return (
        <Router>
            <AuthContextProvider>
                    <AdminContextProvider>
                        <HelperContextProvider>
                            <Banner/>
                            <Switch>
                                <PrivateRoute exact path="/" component={Devices} />
                                <PrivateRoute exact path="/admin" component={Admin} />
                                <PrivateRoute exact path="/change-profile" component={ChangeDisplayName} />
                                <PrivateRoute exact path="/change-password" component={ChangePassword} />
                                <PrivateRoute exact path="/create-account" component={CreateAccount} />
                                <PrivateRoute exact path="/device" component={Device} sensor="pupil_245DFC210022"/>
                                <PrivateRoute exact path="/devicemulti" component={DeviceMulti} />
                                <PrivateRoute exact path="/profile" component={Profile} />
                                <PrivateRoute exact path="/show-license" component={ShowLicense} />
                                <PrivateRoute exact path="/device-average" component={DeviceAverage} />
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/forgot-password" component={ForgotPassword} />
                                {process.env.REACT_APP_DEV_ENV === 'true' && <Route exact path="/test1" component={TestPage1} />}
                                {process.env.REACT_APP_DEV_ENV === 'true' && <Route exact path="/test2" component={TestPage2} />}
                            </Switch>
                            <Footer/>
                        </HelperContextProvider>
                    </AdminContextProvider>
            </AuthContextProvider>
        </Router>
    );
}

export default App;

