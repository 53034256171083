import React, { useState, useContext } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenterEverything from "../components/CenterEverything"
import "./Profile.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import AdminContext from "../contexts/AdminContext"
import { useHelper } from "../contexts/HelperContext"


export default function Profile() {
    const adminCtx = useContext(AdminContext);
    const [getError, setError] = useState("");
    const { currentUser, logout, acceptedLicense, setAllowShowLicense } = useAuth();
    const history = useHistory();
    const [getState, setState] = useState(false);
    const helperCtx = useHelper();

    async function handleLogout() {
        setError("");
        try {
            await logout();
            history.push("/login");
        } catch {
            setError("Failed to log out");
        }
    }

    var email_text = "";
    var displayname_text = "";

    if (currentUser !== null) {
        email_text = currentUser.email;
        displayname_text = adminCtx.displayName;
    }

    const VerifyButton = () => {
        if (getState === true) {
            return (<Button disabled>Verification Email Sent</Button>);
        }
        return (
            <Button onClick={() => {
                currentUser.sendEmailVerification().then(() => {
                    setState(true);
                }).catch((error) => {
                    //alert (error);
                });
            }}>Send Verification Email</Button>
        );
    }

    const SetButton = () => {
        return (
            <Button onClick={() => {
                setAllowShowLicense(true);
                console.log("set agreement true");
                var body = { command: 'updateLicenseAgreement', uid: currentUser.uid, value: true};
                helperCtx.sendHttpRequest('post', body, (response) => {
                    console.log(response.data);
                });
            }}>DEBUG AGREE</Button>
        );
    }
    const ResetButton = () => {
        return (
            <Button onClick={() => {
                setAllowShowLicense(false);
                console.log("set agreement false");
                var body = { command: 'updateLicenseAgreement', uid: currentUser.uid, value: false};
                helperCtx.sendHttpRequest('post', body, (response) => {
                    console.log(response.data);
                });
            }}>DEBUG DISAGREE</Button>
        );
    }


    const AgreeButton = () => {
        return (
            <Button onClick={() => {
                setAllowShowLicense(true);
                console.log("set agreement true");
                var body = { command: 'updateLicenseAgreement', uid: currentUser.uid, value: true};
                helperCtx.sendHttpRequest('post', body, (response) => {
                    console.log(response.data);
                });
            }}>By clicking here, you agree that you have read and agree with the terms of the software agreement</Button>
        );
    }

    const LicenseAgreedTo = () => {
        return (
            <div>
                Agreed to
            </div>
        );
    }
//
    return (
        <div className="profile">
            <CenterEverything>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Profile</h2>
                        {getError && <Alert variant="danger">{getError}</Alert>}
                        <strong>Email:</strong> &nbsp; {email_text} &nbsp;
                        {currentUser.emailVerified ?
                        <FontAwesomeIcon style={{color: "green"}} icon={faCheckCircle}/> : 
                        <VerifyButton/>}
                        <br/>
                        <strong>Display Name:</strong> &nbsp; {displayname_text}
                        <Link to="/change-profile" className="btn btn-primary w-100 mt-3">
                            Change Display Name
                        </Link>
                        <Link to="/change-password" className="btn btn-primary w-100 mt-3">
                            Change Password
                        </Link>
                        <div className="mt-3 d-flex">
                        {
                            acceptedLicense ? <LicenseAgreedTo/> : <AgreeButton/>
                        }
                        &nbsp;&nbsp;
                        {
                            <Link to="/show-license">
                                Software Agreement
                            </Link>
                        }
                        &nbsp;
                        {
                            acceptedLicense ? 
                            <FontAwesomeIcon className="mt-2" style={{color: "green"}} icon={faCheckCircle}/>
                            : ""
                        }
                        </div>
                        <br/>
                        {
                        //<SetButton/>&nbsp;<ResetButton/>
                        }
                        {
                        /*
                        <Button className="mt-3 w-100" onClick={()=>{
                            setAllowShowLicense(true);
                            console.log("set agreement false");
                            var body = { command: 'updateLicenseAgreement', uid: currentUser.uid, value: false};
                            helperCtx.sendHttpRequest('post', body, (response) => {
                                console.log(response.data);
                            });
                        }}>Show License</Button>
                        */
                        }

                        <div className="w-100 text-center mt-2 bottom-text">
                        <Button variant="link" onClick={handleLogout}>Log Out</Button>
                        </div>
                    </Card.Body>
                </Card>
            </CenterEverything>
        </div>
    );
}
