import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom";
import firebase from "../firebase"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import { InputGroup, FormControl, Table, Button, Form, DropdownButton } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { useHelper } from "../contexts/HelperContext"
import "./Devices.css"
import ContinueModal from "../components/ContinueModal"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import MetaModal from "../components/DeviceMetaInput"
import TimeAgo from 'javascript-time-ago'

// English.
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)

/* 
Code that generates ROWS starts with const List = ({ list }) => on line 278
Code that generates column headers and sets table structure is the last return statement (line 566)
*/

export default function Devices() {
    const history = useHistory();

    const [getDevices, setDevices] = useState([]);
    const [getDevicesMeta, setDevicesMeta] = useState([]);
    const cursorStartRef = useRef(-1);
    const cursorEndRef = useRef(-1);
    const indexRef = useRef(-1);
    const rowRef = useRef(-1);
    const buttonHeldRef = useRef(false);

    const isCheckedRef = useRef([]);
    const [getRedraw, setRedraw] = useState(0);

    const [getFilter, setFilter] = useState("");
    const [getShowModal, setShowModal] = useState(false);
    const [getDisplayMeta, setDisplayMeta] = useState("Project");
    const [getDisplayMetric, setDisplayMetric] = useState("Lux");
    const [getDisplayPower, setDisplayPower] = useState("Battery %");
    const timeoutCounterRef = useRef(0);
    const timer1Ref = useRef(null);
    const unsubRef = useRef(null);
    const metaRef = useRef([]);
    const metricRef = useRef([]);
    const powerRef = useRef([]);
    const [getShowMetaModal, setShowMetaModal] = useState(false);
    const deviceIdArrayRef = useRef([]);
    const metaDisplayRef = useRef("");
    const typingTimeoutRef = useRef(null);

    const helperCtx = useHelper();
    const { currentUser } = useAuth();

    // Create time to string formatter
    const timeAgo = new TimeAgo('en-US')

    // fixed meta size
    var meta = helperCtx.initalMetaArray();
    metaRef.current = [];
    meta.forEach((item) => {
        if (item.displayname !== "Name") {
            metaRef.current.push(item.displayname);
        }
    })

    // Initialize viewable metrics. Right now just CS and Lux
    var metricOptions = helperCtx.initializeMetricsArray();
    var metricDict = {} // help us convert from displayed names to data fields
    metricRef.current = []
    metricOptions.forEach((item) => {
        metricRef.current.push(item.displayname)
        metricDict[item.displayname] = item.metricname
    }) 

    function updateTimer() {
        timeoutCounterRef.current++;
        if (timeoutCounterRef.current >= 30) {
            stopIntervalTimer();
            indexRef.current = -1;
            rowRef.current = -1;
            setShowModal(true);
        }
    }

    var reads = 0;

    // Periodically get data from the cloud, push the data into getDevices hook via the devices variable. 
    // Functionally the data from the getDevices hook goes into the const List
    // Currently, we are saving the items displayed in the devices table into the base sensor documents. 
    // This saves us a bunch of unnecessary reading, but means that it's difficult to add new options to the table. 
    function startIntervalTimer() {
        stopIntervalTimer(); // just incase, close out anything that is running

        console.log('starting IntervalTimer');
        timeoutCounterRef.current = 0;
        timer1Ref.current = setInterval(() => {updateTimer();}, 60000);
        updateTimer();
        setShowModal(false);

        unsubRef.current = firebase.firestore().collection("sensors")
        .where("followers", "array-contains", currentUser.uid)
        .onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                console.log("document read", ++reads);
                var id = change.doc.id;
                var data = change.doc.data();      
                var devices = getDevices; 
                var index = helperCtx.getIndexOfId(devices, id);
                if (buttonHeldRef.current === false) {
                    // button held true is a special case.  I dont want any rendering when it is happening
                    if (change.type === 'added' || change.type === 'modified') {
                        if (index === -1) {
                            devices.push ({'id': id, 'data': data, 'index': devices.length});
                            if (data.hasOwnProperty('meta')) {
                                getDevicesMeta.push(data.meta);
                            } else {
                                var tempjson = {};
                                helperCtx.initalMetaArray().forEach((item) => {
                                    tempjson[item.metaname] = "";
                                })
                                console.log(tempjson);
                                getDevicesMeta.push(tempjson);
                            }
                            isCheckedRef.current.push(false);
                        } else {
                            devices[index].data = data;
                            if (data.hasOwnProperty('meta')) {
                                getDevicesMeta[index] = data.meta;
                            }
                        }
                        setDevicesMeta([...getDevicesMeta]);
                    } else if (change.type === 'removed') {
                        devices.splice(index, 1);
                    }
                    setDevices([...devices]);
                }
            });
        });
    }

    function stopIntervalTimer() {
        if (timer1Ref.current !== null) {
            clearInterval(timer1Ref.current);
            timer1Ref.current = null;
            console.log('stopping IntervalTimer');
        }
        if (unsubRef.current !== null) {
            unsubRef.current();
            unsubRef.current = null;
            console.log('Unsubbing to firebase');
        } 
    }

    // set up the page refresh
    useEffect(() => {
        startIntervalTimer();
        return (() => {
            stopIntervalTimer();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function saveMetaData(id, index) {
        var savemeta = getDevicesMeta[index];
        console.log("id", id, "uid", currentUser.uid, "savemeta", savemeta);
        var body = { command: 'updateMeta', id: id, uid: currentUser.uid, meta: savemeta};
        helperCtx.sendHttpRequest('post', body, (response) => {
            console.log(response.data);
        });   
    }

    function FormControlEditBox({id, value, index, row, metaname}) {
        var autofocus = (index === indexRef.current && row === rowRef.current ? true : false);
        var fontcolor = "notediting";
        if (autofocus === true) {
            if (typingTimeoutRef.current !== null) {
                fontcolor = "editing";
                console.log(fontcolor);
            }
        }

        return (<InputGroup className="mb-3">
        <FormControl autoFocus={autofocus}
        className={fontcolor}

        onChange={(event) => {
            getDevicesMeta[index][metaname] = event.target.value;
            setDevicesMeta([...getDevicesMeta]);
            if (typingTimeoutRef.current !== null) {
                clearTimeout(typingTimeoutRef.current);
                typingTimeoutRef.current = null;
            }
            typingTimeoutRef.current = setTimeout(() => {
                console.log("time out save");
                saveMetaData(id, index);
                typingTimeoutRef.current = null;                
            }, 1000);
            cursorStartRef.current = event.target.selectionStart;
            cursorEndRef.current = event.target.selectionEnd;
            //console.log("Store Cursor", cursorStartRef.current, cursorEndRef.current);
        }} 

        onFocus={(event)=>{
            //console.log("onFocus", index, "event", event);
            indexRef.current = index;
            rowRef.current = row;
            if (cursorStartRef.current !== -1) {
                event.target.selectionStart = cursorStartRef.current;
                event.target.selectionEnd = cursorEndRef.current;
                console.log("Recall Cursor", cursorStartRef.current, cursorEndRef.current);
            }
        }}
        onBlur={(event) => {
            //console.log('onBlur', index);

            if (buttonHeldRef.current === false) {
                cursorStartRef.current = -1;
                cursorEndRef.current = -1;
                indexRef.current = -1;
                rowRef.current = -1;
                console.log("Void Cursor", cursorStartRef.current, cursorEndRef.current);
            }


            if (typingTimeoutRef.current !== null) {
                clearTimeout(typingTimeoutRef.current);
                saveMetaData(id, index);
                typingTimeoutRef.current = null;
            }
        }}
        onKeyPress={(event) => {
            //console.log("onKeyPress", index);
            if(event.charCode === 13) {
                indexRef.current = -1;
                rowRef.current = -1;
                setRedraw(getRedraw+1);
                if (typingTimeoutRef.current !== null) {
                    clearTimeout(typingTimeoutRef.current);
                    saveMetaData(id, index);
                    typingTimeoutRef.current = null;
                }
            }
        }}
        onClick={(event)=>{
            cursorStartRef.current = event.target.selectionStart;
            cursorEndRef.current = event.target.selectionEnd;
            console.log("Store Cursor", cursorStartRef.current, cursorEndRef.current);
        }}
        onMouseMove={(event)=>{
            if (event.buttons === 1) {
                buttonHeldRef.current = true;
                if (cursorStartRef.current !== event.target.selectionStart ||
                    cursorEndRef.current !== event.target.selectionEnd) {
                    cursorStartRef.current = event.target.selectionStart;
                    cursorEndRef.current = event.target.selectionEnd;
                    console.log("Store Cursor", cursorStartRef.current, cursorEndRef.current);
                }
            }
        }}
        onMouseUp={()=>{
            buttonHeldRef.current = false;
        }}
        id={index}
        value={value} 
        aria-label="Default" 
        aria-describedby="inputGroup-sizing-default"
        />
        {autofocus && typingTimeoutRef.current !== null && <InputGroup.Text>&nbsp;*</InputGroup.Text>}
        </InputGroup>);

    }

    // This is where the rows of the device page are generated
    const List = ({ list }) => {
        return list.map(
            (item, index) => {      
                var cs = <p style={{color: "rgba(128, 128, 128, 0.75)"}}>{"N/A"}</p> // Used for the lighting column. By default lux. 
                var powerMetric = <p style={{color: "rgba(128, 128, 128, 0.75)"}}>{"N/A"}</p>  // Used for the power column. % if available, Volts if not. 
                var lastSeen = <p style={{color: "rgba(128, 128, 128, 0.75)"}}>{"N/A"}</p>  // Used for hte last seen column. Format is local time zone, M/D/Y, time
                var dataLeft = <p style={{color: "rgba(128, 128, 128, 0.75)"}}>{"N/A"}</p> // Used to track how much data is left on the sensor.
                console.log("LIST ITEM INFO " + item.data.device_id)
                console.log(item.data)
                
                if (item.data.hasOwnProperty(metricDict[getDisplayMetric])) {
                    if (getDisplayMetric == 'CS') {
                        cs = item.data.c_cs.toFixed(2) + " cs";
                    }
                    else if (getDisplayMetric == 'Lux') {
                        cs = item.data.c_slux.toFixed(0) + " lux";
                    }
                    else if (getDisplayMetric == "EML") {
                        cs = item.data.c_eml.toFixed(0) + " lux";
                    }
                    if (item.data.hasOwnProperty('c_cs_ts')) {
                        var lastDate = new Date(item.data.c_cs_ts * 1000).toLocaleString("en-US",{year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
                        lastSeen = <p>{lastDate}</p>
                        // lastSeen = <div>{timeAgo.format(new Date(item.data.c_cs_ts * 1000))}</div> 
                        cs = <p>{cs}</p>;
                    }
                }
                if (item.data.hasOwnProperty('c_volts')) {
                    if ("c_soc" in item.data) { 
                        if (item.data.c_soc >= 60) { powerMetric = <p style={{color: "rgba(76,175,80, 1)"}}>{item.data.c_soc.toFixed(0) + "%"}</p> }
                        else if(item.data.c_soc >= 40) { powerMetric = <p style={{color: "rgba(255,210,63, 1)"}}>{item.data.c_soc.toFixed(0) + "%"}</p> }
                        else { powerMetric = <p style={{color: "rgba(196,12,12, 1)"}}>{item.data.c_soc.toFixed(0) + "%"}</p> }
                    }
                    else if ("c_volts" in item.data) {
                        powerMetric = item.data.c_volts.toFixed(2) + " V";
                    }

                }

                if (item.data.hasOwnProperty('c_backup_num_msgs')) {
                    dataLeft = <div>{item.data.c_backup_num_msgs}</div>
                }

                var dispMeta = getDisplayMeta.toLowerCase().replace(' ', '_');
                var allMeta = getDevicesMeta[index];
                var name = "[undefined]";
                var metaValue = "[undefined]";
                if (allMeta !== undefined) {
                    name = allMeta.name
                    metaValue = allMeta[dispMeta];
                }

                // checkbox
                var ret = (
                <tr key={index}>
                    <td align="middle">
                        <Form className='d-flex align-items-right'>
                            <Form.Check
                                inline="False"
                                name="group1"
                                type="checkbox"
                                id={index}
                                checked={isCheckedRef.current[index]}
                                onChange={
                                    () => {
                                        isCheckedRef.current[index] = !isCheckedRef.current[index];
                                        setRedraw(getRedraw+1);
                                    }
                                }
                            />
                        </Form>
                    </td>
                    <td>
                        {item.type}<Link to={"/device?id=" + item.id}>{helperCtx.translateId(item.id)}</Link>
                    </td>
                    <td>
                        <FormControlEditBox id={item.id} value={name} index={index} row={0} metaname="name" />
                    </td>
                    <td>
                        <FormControlEditBox id={item.id} value={metaValue} index={index} row={1} metaname={dispMeta} />
                    </td>
                    <td>{cs}</td>
                    <td>{powerMetric}</td>
                    <td>{lastSeen}</td>
                    <td>{dataLeft}</td>
                </tr>);

                var disp = true;
                if (getFilter !== "") {
                    disp = false;
                    // filter out the object
                    var filterValue = getFilter.toLowerCase();
                    if (helperCtx.translateId(item.id).toLowerCase().includes(filterValue) === true) {
                        disp = true;
                    }
                    if (metaValue.toLowerCase().includes(filterValue) === true) {
                        disp = true;
                    }
                    if (name.toLowerCase().includes(filterValue) === true) {
                        disp = true;
                    }
                }

                if (disp === false) {
                    return <tr key={index}></tr>;
                }

                return ret;
            }
        );
    }
  
    
    function handleFilterOnChange(event) {
        setFilter(event.target.value);
    }    

    function MetaDropDown() {
        if (metaRef.current.length === 0) {
            return <div/>;
        }
        return (
            <div >
            {getShowingMeta()}
            &nbsp;
            <DropdownButton style={{display: 'inline-block'}} title="">
            {
                metaRef.current.map((value, index) => {
                    return (<DropdownItem key={index} onClick={()=>{setDisplayMeta(value);}}>{value}</DropdownItem>);
                })
            }
            </DropdownButton>
            </div>
        );
    }

    // Helper fuction to get what the current meta topic the user set is
    function getShowingMeta() {
        if (getDisplayMeta === "") {
            return "Select Meta Topic";
        }
        return getDisplayMeta;
    }

    function MetricDropDown() {
        if (metricRef.current.length === 0) {
            return <div/>;
        }
        return (
            <div >
            {getShowingMetric()}
            &nbsp;
            <DropdownButton style={{display: 'inline-block'}} title="">
            {
                metricRef.current.map((value, index) => {
                    return (<DropdownItem key={index} onClick={()=>{setDisplayMetric(value);}}>{value}</DropdownItem>);
                })
            }
            </DropdownButton>
            </div>
        );
    }

    function getShowingMetric() {
        if (getDisplayMetric === "") {
            return "Select Metric";
        }
        return getDisplayMetric;
    }

    function PowerDropDown() {
        return (
            <div >
            {getShowingMetric()}
            &nbsp;
            <DropdownButton style={{display: 'inline-block'}} title="">
            {
                powerRef.current.map((value, index) => {
                    return (<DropdownItem key={index} onClick={()=>{setDisplayPower(value);}}>{value}</DropdownItem>);
                })
            }
            </DropdownButton>
            </div>
        );
    }    

    // Defines behavior for the multi edit button. 
    function MetaButton() {
        var button_enable = false;
        isCheckedRef.current.forEach((item) => {
            if (item === true) {
                button_enable = true;
            }
        });
        if (getDisplayMeta !== "") {
            if (button_enable === true) {
                return <Button className="btn" onClick={
                    () => {
                        var devices = getDevices.filter(
                            (item) => {
                                return isCheckedRef.current[item.index];
                            }
                        );
                        var arry = [];
                        devices.forEach((item) => {
                            arry.push(item.id);
                        });
                        deviceIdArrayRef.current = arry;
                        if (arry.length === 1) {
                            metaDisplayRef.current = "";
                        } else {
                            metaDisplayRef.current = getDisplayMeta.toLowerCase().replace(' ', '_');
                        }
                        console.log("metaDisplayRef.current", metaDisplayRef.current);
                        indexRef.current = -1;
                        rowRef.current = -1;
                        setShowMetaModal(true);
                    }
                }>Multi-Edit</Button>;
            }
        }
        return <Button className="btn" disabled={true}>Multi-Edit</Button>;
    }

    function ViewSensorsButton() {
        var checkcount = 0;
        var disabled = true;
        isCheckedRef.current.forEach((item) => {
            if (item === true) {
                checkcount++;
            }
        })
        if (checkcount <= 4) {
            disabled = false;
        }
        return <Button className="btn" disabled={disabled} onClick={
            () => {
                var devices = "";
                var count = 0;
                isCheckedRef.current.forEach((item, index) => {
                    if (item === true) {
                        if (count === 0) {
                            devices = devices + "?dev" + count + "=" + getDevices[index].id;
                        }
                        else {
                            devices = devices + "&dev" + count + "=" + getDevices[index].id;
                        }
                        count++;
                    }
                })
                history.push("/devicemulti" + devices);
            }
        }>Multi-sensor View</Button>;
    }

    function AverageSensorsButton() {
        var checkcount = 0;
        var disabled = true;
        isCheckedRef.current.forEach((item) => {
            if (item === true) {
                checkcount++;
            }
        })
        if (checkcount <= 4) {
            disabled = false;
        }
        return <Button className="btn" disabled={disabled} onClick={
            () => {
                var devices = "";
                var count = 0;
                isCheckedRef.current.forEach((item, index) => {
                    if (item === true) {
                        if (count === 0) {
                            devices = devices + "?dev" + count + "=" + getDevices[index].id;
                        }
                        else {
                            devices = devices + "&dev" + count + "=" + getDevices[index].id;
                        }
                        count++;
                    }
                })
                history.push("/device-average" + devices);
            }
        }>Sensor Average View</Button>;
    }


    return (
        <div className="container devices">

            <div className="d-flex flex-row mt-4 flex-row-reverse">
                <Button 
                    className="btn" 
                    href="/files/BIL_Portal Manual_V2.pdf" 
                    download> Download
                </Button>
                Speck Setup Manual&nbsp;
            </div>

            <div className="mt-4">
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text>Filter</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl 
                        onChange={handleFilterOnChange} 
                        aria-label="Default" 
                        aria-describedby="inputGroup-sizing-default"
                    />
                </InputGroup>
            </div>

            <div className="">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Device</th>
                            <th>Name</th>
                            <th><MetaDropDown/></th>
                            <th><MetricDropDown/></th>
                            <th>Battery</th>
                            <th>Last Seen</th>
                            <th>Unsynchronized Data Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        <List list={getDevices}/>
                        <tr>
                            <td></td>
                            <td align="middle">
                            <ViewSensorsButton/>
                            <br/>
                            <div className="mt-2">
                                {/*
                            <AverageSensorsButton />
                                */}
                            </div>
                            </td>
                            <td></td>
                            <td align="middle"><MetaButton/></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            {getShowMetaModal && 
                <MetaModal 
                    onHide={() => {setShowMetaModal(false);}} 
                    deviceIdArray={deviceIdArrayRef.current}
                    metaDisplay={metaDisplayRef.current}
                />
            }

            {getShowModal && <ContinueModal 
                message={["Page refreshing paused.", "Press CONTINUE to unpause."]}
                onHide={() => {startIntervalTimer();}} 
            />}

        </div>
 
    );
}

