import React, {useEffect, useRef} from "react"
import firebase from "../firebase"
import { useHelper } from "../contexts/HelperContext"
import "./DevicePage.css"

export default function DevicePageData({
    sensorId, 
    getTimeRange, 
    dateValueRef, 
    timeRangeRef, 
    setStartAndEndDateTime,
    dateStartRef,
    dateEndRef,
    setParentHideSpinnerCount,
    setSpdData,
}) {

    const bucketRef = useRef([]);
    const helperCtx = useHelper();
    
    // EFFECT START
    useEffect(() => {
        var unsubscribeFromDB = null;

        if (sensorId !== "") {
            // Decode the date selected
            var selectedDateStart = Date.parse(dateValueRef.current) / 1000;

            var selectedDateQueryStart;
            var selectedDateQueryEnd;

            selectedDateQueryEnd = selectedDateStart + (60 * 60 * 24);
            selectedDateQueryStart = selectedDateStart - (60 * 60 * 24) - (60 * 60 * timeRangeRef.current) ;
            setStartAndEndDateTime(sensorId, "4");

            // Subscribe to the database for the selected time

            // unscubscribeFromDB is the compliment function to the document subscribe statement.
            // The useEffect() statement runs the body of the function and waits for getDateValue to 
            // change before redrawing.
            unsubscribeFromDB = firebase.firestore().collection("sensors").doc(sensorId).collection("ch")
            .where('t0', '>=', selectedDateQueryStart)
            .where('t0', '<', selectedDateQueryEnd)
            .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    setParentHideSpinnerCount( c => c + 1 );
                    setTimeout(() => {
                        let timerId = helperCtx.createTimer();
                        helperCtx.currentTimerTime(timerId); // start timer
                        var data = change.doc.data();
                        var bucket = bucketRef.current;
                        for (var j = 0; j < data.ts.length; ++j) {
                            var ts = data.ts[j];
                            if (helperCtx.arrayContainsTs(bucket, ts) === false) {
                                var f1 = data.f1[j];
                                var f2 = data.f2[j];
                                var f3 = data.f3[j];
                                var f4 = data.f4[j];
                                var f5 = data.f5[j];
                                var f6 = data.f6[j];
                                var f7 = data.f7[j];
                                var f8 = data.f8[j];
                                var clr = data.clr[j];
                                var nir = data.nir[j];
                                var X = data.X[j];
                                var Y = data.Y[j];
                                var Z = data.Z[j];
                                var XX = X / (X + Y + Z);
                                var YY = Y / (X + Y + Z);
                                //var obj1 = helperCtx.get6Vars(ts,f1,f2,f3,f4,f5,f6,f7,f8,clr,nir,X,Y,Z);
                                var obj1 = helperCtx.get6VarsInit(ts);
                                var obj2 = { X: X, Y: Y, Z: Z, XX: XX, YY: YY, f1: f1, f2: f2, f3: f3, f4: f4, f5: f5, f6: f6, f7: f7, f8: f8, clr: clr, nir: nir }
                                var mergedObj = { ...obj1, ...obj2 };
                                bucket.push(mergedObj);
                            }
                        }
                        
                        bucketRef.current = bucket;
                        setStartAndEndDateTime(sensorId, "3");

                        var dateStart = dateStartRef.current;
                        var dateEnd = dateEndRef.current;
                        var spdData = []; // clear the list
                        // stuff everything into the list
                        var i;
                        for (i = 0; i < bucket.length; i++) {
                            if (bucket[i].ts >= dateStart && bucket[i].ts <= dateEnd) {
                                if (bucket[i].calculated === false) {
                                    var obj3 = helperCtx.get6Vars(
                                        bucket[i].ts,
                                        bucket[i].f1,
                                        bucket[i].f2,
                                        bucket[i].f3,
                                        bucket[i].f4,
                                        bucket[i].f5,
                                        bucket[i].f6,
                                        bucket[i].f7,
                                        bucket[i].f8,
                                        bucket[i].clr,
                                        bucket[i].nir,
                                        bucket[i].X,
                                        bucket[i].Y,
                                        bucket[i].Z
                                    );
                                    bucket[i] = {...bucket[i], ...obj3};
                                };
                            }
                        }

                        for (i = 0; i < bucket.length; i++) {
                            if (bucket[i].ts >= dateStart && bucket[i].ts <= dateEnd) {
                                helperCtx.insertInOrder (spdData, {
                                    x: bucket[i].ts, 
                                    lux: bucket[i].lux, 
                                    cs: bucket[i].cs, 
                                    eml: bucket[i].eml, 
                                    cct: bucket[i].cct,
                                    spd: bucket[i].spd,
                                    XX: bucket[i].XX, 
                                    YY: bucket[i].YY
                                });
                            }
                        }
                        setSpdData(spdData);
                        setParentHideSpinnerCount( c => c - 1 );
                        helperCtx.deleteTimer(timerId); // timer no longer in use
                    }, 10);
                });
            });
        }

        // return is called when getDateValue is called
        return (() => {
            if (unsubscribeFromDB !== null)
                unsubscribeFromDB();
            bucketRef.current = [];
            setSpdData([]);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTimeRange, sensorId]);
    // EFFECT STOP


    return <div/>; // dont display anything
}
