import React, { useEffect } from "react"
import "./Banner.css"
import BILLogo from "../logo.png"
import { Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { useAdmin } from "../contexts/AdminContext"
import { useHelper } from "../contexts/HelperContext"

export default function Banner() {
    const { currentUser } = useAuth();
    const { displayName, isAdminAccount } = useAdmin();
    const helperCtx = useHelper();

    var admin = "";
    var devices = "";
    var profile = "";
    var name = "";

    if (currentUser !== null)
    {
        if (isAdminAccount === true) {
            admin = <Link to="/admin">Admin</Link>;
        }
        devices = <Link to="/">Devices</Link>;
        profile = <Link to="/profile">Profile</Link>;
        name = displayName;
    }

    useEffect(()=> {
        var box = document.querySelector('.banner');
        if (box !== null) {
            helperCtx.setBannerHeight(box.offsetHeight);
        }    
        return (()=>{});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  
    return (
        <Navbar className="p-4 banner d-flex flex-row w-100">
            <img className="p-2 justify-content-start logo" src={BILLogo} alt="Blue Iris Labs logo" />
            <div className="p-2 black">{process.env.REACT_APP_DEV_ENV === 'true' && "[development]"}</div>
            <div className="p-2">{admin}</div>
            <div className="p-2">{profile}</div>
            <div className="p-2">{devices}</div>
            <div className="p-2 black ms-auto">{name}</div>
        </Navbar>
    );
}