export const spectrumColors = 
[
    {r: 66, g: 26, b: 82},
    {r: 67, g: 27, b: 83},
    {r: 68, g: 28, b: 84},
    {r: 69, g: 29, b: 86},
    {r: 70, g: 30, b: 87},
    {r: 71, g: 31, b: 89},
    {r: 72, g: 32, b: 90},
    {r: 73, g: 33, b: 91},
    {r: 74, g: 34, b: 93},
    {r: 75, g: 35, b: 94},
    {r: 76, g: 35, b: 95},
    {r: 77, g: 36, b: 96},
    {r: 78, g: 37, b: 98},
    {r: 79, g: 38, b: 99},
    {r: 80, g: 39, b: 100},
    {r: 81, g: 40, b: 101},
    {r: 82, g: 41, b: 102},
    {r: 83, g: 42, b: 104},
    {r: 85, g: 43, b: 105},
    {r: 86, g: 43, b: 106},
    {r: 87, g: 44, b: 108},
    {r: 88, g: 45, b: 109},
    {r: 89, g: 46, b: 110},
    {r: 90, g: 47, b: 112},
    {r: 91, g: 48, b: 113},
    {r: 92, g: 48, b: 114},
    {r: 92, g: 49, b: 115},
    {r: 90, g: 50, b: 116},
    {r: 90, g: 51, b: 117},
    {r: 88, g: 51, b: 118},
    {r: 87, g: 53, b: 119},
    {r: 87, g: 53, b: 120},
    {r: 85, g: 54, b: 121},
    {r: 84, g: 55, b: 122},
    {r: 83, g: 55, b: 123},
    {r: 82, g: 56, b: 124},
    {r: 81, g: 57, b: 125},
    {r: 80, g: 57, b: 126},
    {r: 79, g: 58, b: 127},
    {r: 77, g: 59, b: 128},
    {r: 76, g: 59, b: 129},
    {r: 75, g: 60, b: 130},
    {r: 73, g: 61, b: 131},
    {r: 73, g: 61, b: 131},
    {r: 71, g: 62, b: 132},
    {r: 69, g: 62, b: 133},
    {r: 68, g: 63, b: 134},
    {r: 67, g: 64, b: 135},
    {r: 65, g: 65, b: 136},
    {r: 64, g: 65, b: 137},
    {r: 62, g: 66, b: 138},
    {r: 60, g: 66, b: 139},
    {r: 58, g: 67, b: 140},
    {r: 56, g: 68, b: 141},
    {r: 54, g: 68, b: 142},
    {r: 53, g: 69, b: 143},
    {r: 51, g: 70, b: 145},
    {r: 48, g: 70, b: 146},
    {r: 47, g: 71, b: 147},
    {r: 46, g: 71, b: 148},
    {r: 43, g: 72, b: 150},
    {r: 41, g: 72, b: 151},
    {r: 39, g: 73, b: 152},
    {r: 35, g: 74, b: 153},
    {r: 33, g: 74, b: 154},
    {r: 29, g: 75, b: 156},
    {r: 26, g: 75, b: 157},
    {r: 23, g: 76, b: 158},
    {r: 20, g: 76, b: 159},
    {r: 17, g: 76, b: 160},
    {r: 15, g: 77, b: 161},
    {r: 10, g: 78, b: 163},
    {r: 3, g: 82, b: 165},
    {r: 0, g: 84, b: 166},
    {r: 0, g: 87, b: 168},
    {r: 0, g: 90, b: 171},
    {r: 0, g: 93, b: 172},
    {r: 0, g: 95, b: 174},
    {r: 0, g: 99, b: 177},
    {r: 0, g: 101, b: 179},
    {r: 0, g: 104, b: 181},
    {r: 0, g: 107, b: 183},
    {r: 0, g: 109, b: 184},
    {r: 0, g: 112, b: 187},
    {r: 0, g: 116, b: 189},
    {r: 0, g: 117, b: 191},
    {r: 0, g: 120, b: 193},
    {r: 0, g: 123, b: 196},
    {r: 0, g: 125, b: 197},
    {r: 0, g: 128, b: 200},
    {r: 0, g: 131, b: 203},
    {r: 0, g: 133, b: 205},
    {r: 0, g: 136, b: 207},
    {r: 0, g: 139, b: 210},
    {r: 0, g: 141, b: 212},
    {r: 0, g: 144, b: 214},
    {r: 0, g: 148, b: 217},
    {r: 0, g: 150, b: 219},
    {r: 0, g: 153, b: 221},
    {r: 0, g: 156, b: 223},
    {r: 0, g: 158, b: 225},
    {r: 0, g: 161, b: 228},
    {r: 0, g: 165, b: 231},
    {r: 0, g: 167, b: 233},
    {r: 0, g: 170, b: 236},
    {r: 0, g: 173, b: 239},
    {r: 0, g: 174, b: 235},
    {r: 0, g: 173, b: 230},
    {r: 0, g: 173, b: 225},
    {r: 0, g: 172, b: 220},
    {r: 0, g: 172, b: 215},
    {r: 0, g: 172, b: 209},
    {r: 0, g: 171, b: 206},
    {r: 0, g: 171, b: 202},
    {r: 0, g: 171, b: 197},
    {r: 0, g: 171, b: 194},
    {r: 0, g: 171, b: 188},
    {r: 0, g: 170, b: 184},
    {r: 0, g: 170, b: 180},
    {r: 0, g: 170, b: 175},
    {r: 0, g: 170, b: 170},
    {r: 0, g: 169, b: 166},
    {r: 0, g: 169, b: 162},
    {r: 0, g: 169, b: 156},
    {r: 0, g: 169, b: 153},
    {r: 0, g: 169, b: 148},
    {r: 0, g: 168, b: 144},
    {r: 0, g: 168, b: 141},
    {r: 0, g: 168, b: 135},
    {r: 0, g: 168, b: 130},
    {r: 0, g: 167, b: 126},
    {r: 0, g: 167, b: 121},
    {r: 0, g: 167, b: 116},
    {r: 0, g: 167, b: 112},
    {r: 0, g: 167, b: 107},
    {r: 0, g: 166, b: 102},
    {r: 0, g: 166, b: 99},
    {r: 0, g: 166, b: 95},
    {r: 0, g: 166, b: 90},
    {r: 0, g: 166, b: 88},
    {r: 0, g: 166, b: 84},
    {r: 0, g: 167, b: 81},
    {r: 0, g: 168, b: 80},
    {r: 0, g: 170, b: 79},
    {r: 0, g: 171, b: 79},
    {r: 0, g: 173, b: 78},
    {r: 11, g: 175, b: 77},
    {r: 25, g: 177, b: 76},
    {r: 36, g: 178, b: 75},
    {r: 49, g: 180, b: 75},
    {r: 64, g: 182, b: 73},
    {r: 71, g: 183, b: 73},
    {r: 85, g: 186, b: 71},
    {r: 96, g: 187, b: 70},
    {r: 104, g: 189, b: 69},
    {r: 114, g: 191, b: 68},
    {r: 123, g: 193, b: 66},
    {r: 128, g: 195, b: 65},
    {r: 137, g: 197, b: 64},
    {r: 145, g: 199, b: 62},
    {r: 151, g: 201, b: 60},
    {r: 159, g: 204, b: 58},
    {r: 167, g: 206, b: 56},
    {r: 172, g: 208, b: 55},
    {r: 179, g: 211, b: 52},
    {r: 188, g: 214, b: 48},
    {r: 192, g: 215, b: 47},
    {r: 201, g: 218, b: 43},
    {r: 208, g: 221, b: 38},
    {r: 214, g: 223, b: 34},
    {r: 220, g: 225, b: 28},
    {r: 227, g: 228, b: 23},
    {r: 233, g: 230, b: 18},
    {r: 238, g: 233, b: 14},
    {r: 244, g: 236, b: 9},
    {r: 248, g: 238, b: 6},
    {r: 254, g: 241, b: 1},
    {r: 255, g: 239, b: 0},
    {r: 255, g: 237, b: 0},
    {r: 255, g: 233, b: 0},
    {r: 255, g: 230, b: 0},
    {r: 255, g: 228, b: 0},
    {r: 255, g: 224, b: 0},
    {r: 255, g: 221, b: 0},
    {r: 255, g: 218, b: 0},
    {r: 255, g: 215, b: 1},
    {r: 255, g: 213, b: 3},
    {r: 255, g: 210, b: 4},
    {r: 255, g: 207, b: 6},
    {r: 255, g: 203, b: 8},
    {r: 255, g: 202, b: 9},
    {r: 255, g: 198, b: 11},
    {r: 255, g: 195, b: 13},
    {r: 255, g: 193, b: 14},
    {r: 254, g: 189, b: 16},
    {r: 254, g: 186, b: 18},
    {r: 253, g: 184, b: 19},
    {r: 253, g: 181, b: 20},
    {r: 252, g: 178, b: 21},
    {r: 252, g: 175, b: 23},
    {r: 251, g: 172, b: 24},
    {r: 251, g: 168, b: 25},
    {r: 250, g: 167, b: 26},
    {r: 250, g: 164, b: 26},
    {r: 249, g: 161, b: 27},
    {r: 249, g: 159, b: 27},
    {r: 248, g: 155, b: 28},
    {r: 248, g: 152, b: 28},
    {r: 247, g: 150, b: 29},
    {r: 247, g: 148, b: 29},
    {r: 247, g: 145, b: 29},
    {r: 246, g: 142, b: 30},
    {r: 246, g: 139, b: 30},
    {r: 246, g: 136, b: 31},
    {r: 246, g: 135, b: 31},
    {r: 246, g: 132, b: 31},
    {r: 245, g: 128, b: 32},
    {r: 245, g: 127, b: 32},
    {r: 245, g: 126, b: 32},
    {r: 245, g: 124, b: 32},
    {r: 245, g: 123, b: 32},
    {r: 244, g: 122, b: 32},
    {r: 244, g: 120, b: 32},
    {r: 244, g: 118, b: 33},
    {r: 244, g: 117, b: 33},
    {r: 244, g: 115, b: 33},
    {r: 244, g: 115, b: 33},
    {r: 243, g: 113, b: 33},
    {r: 243, g: 111, b: 33},
    {r: 243, g: 111, b: 33},
    {r: 243, g: 108, b: 33},
    {r: 243, g: 107, b: 33},
    {r: 243, g: 106, b: 34},
    {r: 242, g: 104, b: 34},
    {r: 242, g: 103, b: 34},
    {r: 242, g: 102, b: 34},
    {r: 242, g: 100, b: 34},
    {r: 242, g: 98, b: 34},
    {r: 242, g: 97, b: 34},
    {r: 241, g: 95, b: 34},
    {r: 241, g: 93, b: 34},
    {r: 241, g: 92, b: 34},
    {r: 241, g: 90, b: 34},
    {r: 241, g: 88, b: 34},
    {r: 241, g: 88, b: 34},
    {r: 240, g: 85, b: 35},
    {r: 240, g: 83, b: 35},
    {r: 240, g: 82, b: 35},
    {r: 240, g: 80, b: 35},
    {r: 240, g: 78, b: 35},
    {r: 240, g: 78, b: 35},
    {r: 239, g: 76, b: 35},
    {r: 239, g: 74, b: 35},
    {r: 239, g: 72, b: 35},
    {r: 239, g: 69, b: 35},
    {r: 239, g: 67, b: 35},
    {r: 239, g: 65, b: 35},
    {r: 239, g: 63, b: 35},
    {r: 238, g: 60, b: 35},
    {r: 238, g: 57, b: 35},
    {r: 238, g: 54, b: 35},
    {r: 238, g: 51, b: 35},
    {r: 238, g: 50, b: 35},
    {r: 238, g: 47, b: 36},
    {r: 238, g: 45, b: 36},
    {r: 238, g: 43, b: 36},
    {r: 238, g: 40, b: 36},
    {r: 237, g: 36, b: 36},
    {r: 237, g: 35, b: 36},
    {r: 237, g: 32, b: 36},
    {r: 237, g: 29, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
    {r: 237, g: 28, b: 36},
];


export default spectrumColors;