import React from "react"
import "./ContinueModal.css"
import {Button} from "react-bootstrap"

export default function ContinueModal({message, onHide}) {

    function Decode() {
        return message.map((element, index) => {
            return (<h4 key={index} className="m-3 mdl-title">{element}</h4>);
        });
    }

    return (
        <div>
            <div className="mdl">
                <div className="mdl-content">
                    <div className="mdl-body">
                    <Decode />
                    </div>
                    <div className="mdl-footer d-flex">
                        <Button className="m-3 ms-auto" variant="primary" onClick={()=>{onHide()}}>Continue</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
