import React, { useState, useEffect } from "react"
import "./Footer.css"
import versiontext from "../version.txt"
import { useHelper } from "../contexts/HelperContext"

export default function Footer() {
    const [version, setVersion] = useState("");
    const helperCtx = useHelper();

    useEffect(() => {
        // load version
        fetch(versiontext).then( r => r.text() )
        .then( (ver) => {
            console.log("Version", ver);
            setVersion(ver);
        });

        function handleResize() {
            var box = document.querySelector('.footer');
            if (box !== null) {
                helperCtx.setFooterHeight(box.offsetHeight);
            }    
        }
        var fbox = document.querySelector('.footer');
        if (fbox !== null) {
            helperCtx.setFooterHeight(fbox.offsetHeight);
        }    
        window.addEventListener('resize', handleResize);

        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // without the &nbsp; the offsetHeight is not rendered correctly
    return (
        <div>
            &nbsp;
            <div className="footer">Blue Iris Labs {version}</div>
        </div>
    );
}