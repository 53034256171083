import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenterEverything from "../components/CenterEverything"
import "./Profile.css"

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [getError, setError] = useState("");
    const [getLoading, setLoading] = useState(false);
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError("");
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            history.push("/");
        } catch {
            setError("Failed to log in");
            setLoading(false);
        }
    }
  
  return (
        <div className="profile">
            <CenterEverything>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Log In</h2>
                        {getError && <Alert variant="danger">{getError}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label className="label">Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required />
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label className="label">Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required />
                            </Form.Group>
                            <Button disabled={getLoading} className="w-100 mt-3" type="submit">Log In</Button>
                            <div className="w-100 text-center mt-3 bottom-text">
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
                { false && 
                  <div className="w-100 text-center mt-3">
                      Need an account? <Link to="/create-account">Create Account</Link>
                  </div>
                }
            </CenterEverything>
        </div>
    );
}
