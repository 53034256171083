import { Spinner, Dropdown, DropdownButton } from "react-bootstrap"
import DatePicker from "react-date-picker"
import "./DeviceGraphRow2.css"

export default function DeviceGraphRow2({
    sensor,
    getDateValue, setDateValue, 
    getHideSpinnerCount,
    getTimeDivision, setTimeDivision,
    setTimeRange,
    setStartAndEndDateTime,
    getTimeRangeRef,
    setTimeRangeRef,
    timeRangeIsReduced,
    setDateValueRef,
    setQueueSetIndexToEndRef,
}) {
    function TimeDivision({onChange}) {
        var ret = [];
        var s = (getTimeDivision === 1) ? " " : "s";
        var titleText = getTimeDivision + " Minute" + s;

        ret.push(<TimeDivisionDropdownItem key="1" value="1" onChange={onChange}/>);
        ret.push(<TimeDivisionDropdownItem key="5" value="5" onChange={onChange}/>);
        ret.push(<TimeDivisionDropdownItem key="15" value="15" onChange={onChange}/>);
        ret.push(<TimeDivisionDropdownItem key="30" value="30" onChange={onChange}/>);

        return (
            <DropdownButton size="sm" id="dropdown-basic" title={titleText} onChange={onChange}>
                {ret}
            </DropdownButton>
        );
    }
    function TimeDivisionDropdownItem({value, onChange}) {
        var s = (value === 1) ? " " : "s";
        var titleText = value + " Minute" + s;
        return (<Dropdown.Item onClick={() => {onChange(value);}}>{titleText}</Dropdown.Item>);
    }

    function timeDivisionOnChangeHandler(value) {
        setTimeDivision(parseInt(value));
    }

    function TimeRangeDropdownItem({time, onChange}) {
        var s = (time === '1') ? '' : 's';
        return (<Dropdown.Item onClick={() => {onChange(time);}}>{time} Hour{s}</Dropdown.Item>);
    }

    function TimeRangeDropdown({onChange}) {
        var ret = [];

        var s = (getTimeRangeRef !== 1) ? 's': '';
        var titleText = getTimeRangeRef + ' hour' + s;

        if (timeRangeIsReduced === false) {
            ret.push(<TimeRangeDropdownItem key="0" time="1" onChange={onChange}/>);
            ret.push(<TimeRangeDropdownItem key="1" time="2" onChange={onChange}/>);
            ret.push(<TimeRangeDropdownItem key="2" time="3" onChange={onChange}/>);
            ret.push(<TimeRangeDropdownItem key="3" time="6" onChange={onChange}/>);
            ret.push(<TimeRangeDropdownItem key="4" time="12" onChange={onChange}/>);
        }
        ret.push(<TimeRangeDropdownItem key="5" time="24" onChange={onChange}/>);
        ret.push(<TimeRangeDropdownItem key="6" time="48" onChange={onChange}/>);
        ret.push(<TimeRangeDropdownItem key="7" time="72" onChange={onChange}/>);

        return (
            <DropdownButton size="sm" id="dropdown-basic" title={titleText} onChange={onChange}>
                {ret}
            </DropdownButton>
        );
    }

    function handleDatePickerOnChange(value) {
        setDateValue(value);
        setDateValueRef(value);
        setTimeRange(24);
        setTimeRangeRef(24);
        setTimeDivision(15);
        setStartAndEndDateTime(sensor, "1");
        setQueueSetIndexToEndRef(true);
    }

    function timeRangeOnChangeHandler(time) {
        setTimeRange(time);
        setTimeRangeRef(time);
        setStartAndEndDateTime(sensor, "2");
        if (time < 6)
            setTimeDivision(1);
        else if (time >= 6 && time < 24)
            setTimeDivision(5);
        else if (time >= 24 && time < 72)
            setTimeDivision(15);
        else
            setTimeDivision(30);
        setQueueSetIndexToEndRef(true);
    }


    // Responsive TopRow
    var TopRow = <div>Row2</div>;
    var width = 1024;
    var box = document.querySelector('.container');
    if (box !== null) {
        width = box.offsetWidth;
    }



    if (width > 430) {
        TopRow = (<div className="d-flex flex-row">
            <div className="p-2">
                <div className="d-flex flex-row">Date</div>
                <DatePicker className="datepick" onChange={handleDatePickerOnChange} value={getDateValue} clearIcon=""/>
            </div>
            <div className="p-2">
                Time Range
                <TimeRangeDropdown onChange={timeRangeOnChangeHandler}/>
            </div> 
            <div className="p-2">
                Division
                <TimeDivision onChange={timeDivisionOnChangeHandler}/>
            </div>
            <div className="mt-4 p-2">
                {(getHideSpinnerCount > 0) && <Spinner animation="border" />}
            </div>
        </div>);

    } else if (width > 260) {
        TopRow = (<div>
            <div className="d-flex flex-row">
                <div className="p-2">
                    <div className="d-flex flex-row">Date</div>
                    <DatePicker className="datepick" onChange={handleDatePickerOnChange} value={getDateValue} clearIcon=""/>
                </div>
            </div>
            <div className="d-flex flex-row">
                <div className="p-2">
                    Time Range
                    <TimeRangeDropdown onChange={timeRangeOnChangeHandler}/>
                </div> 
                <div className="p-2">
                    Division
                    <TimeDivision onChange={timeDivisionOnChangeHandler}/>
                </div>
                <div className="mt-4 p-2">
                    {(getHideSpinnerCount > 0) && <Spinner animation="border" />}
                </div>
            </div>
        </div>);
    } else {
        TopRow = (<div>
            <div className="d-flex flex-row">
                <div className="p-2">
                    <div className="d-flex flex-row">Date</div>
                    <DatePicker className="datepick" onChange={handleDatePickerOnChange} value={getDateValue} clearIcon=""/>
                </div>
            </div>
            <div className="d-flex flex-row">
                <div className="p-2">
                    Time Range
                    <TimeRangeDropdown onChange={timeRangeOnChangeHandler}/>
                </div> 
            </div>
            <div className="d-flex flex-row">
                <div className="p-2">
                    Division
                    <TimeDivision onChange={timeDivisionOnChangeHandler}/>
                </div>
                <div className="mt-4 p-2">
                    {(getHideSpinnerCount > 0) && <Spinner animation="border" />}
                </div>
            </div>
        </div>);
    }

    return (
        <div>{TopRow}</div>
    );
}