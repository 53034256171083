import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import CenterEverything from "../components/CenterEverything"

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth();
    const [getError, setError] = useState("")
    const [getMessage, setMessage] = useState("")
    const [getLoading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage("");
            setError("");
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage("Check your inbox for further instructions");
        } catch {
            setError("Failed to reset password");
        }
        setLoading(false);
    }

    return (
        <div>
            <CenterEverything>
                <div className="profile">
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">Password Reset</h2>
                            {getError && <Alert variant="danger">{getError}</Alert>}
                            {getMessage && <Alert variant="success">{getMessage}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                <Form.Label className="label">Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                                <Button disabled={getLoading} className="w-100 mt-3" type="submit">
                                Reset Password
                                </Button>
                                <div className="w-100 text-center mt-3 bottom-text">
                                    <Link to="/login">Cancel</Link>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    {false &&
                        <div className="w-100 text-center mt-2">
                            Need an account? <Link to="/create-account">Create Account</Link>
                        </div>
                    }
                </div>
            </CenterEverything>
        </div>
    );
}
