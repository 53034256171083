import { ButtonGroup, Button } from "react-bootstrap"
import { useHelper } from "../contexts/HelperContext"
import {DatePicker} from 'antd'
import dayjs from 'dayjs';
import "./DeviceGraphRow1.css"

export default function DeviceGraphRow1({deviceId, rangePickerChanged, cbDownloadDataPressed, getGraphView, setGraphView}) {
    const helperCtx = useHelper();
    const { RangePicker } = DatePicker;
    // Responsive Row
    var Row = <div></div>;
    var width = 1024;
    var box = document.querySelector('.container');
    if (box !== null) {
        width = box.offsetWidth;
    }
    const RowLeft = (
        <div className="row1">
            <ButtonGroup>
                <Button variant={getGraphView === 0 ? "primary" : "secondary"} onClick={() => {setGraphView(0)}}>Graph</Button>
                <Button variant={getGraphView === 1 ? "primary" : "secondary"} onClick={() => {setGraphView(1)}}>Spectrum</Button>
            </ButtonGroup>
        </div>
    );
    const RowMiddle = (
        <div className="row1">                
            {helperCtx.translateId(deviceId)}
        </div>
    );
    const RowRight = (
        <div className="row1">
            <RangePicker 
                defaultPickerValue = {[dayjs(),dayjs()]} 
                defaultValue = {[dayjs(),dayjs()]} 
                onCalendarChange={rangePickerChanged}
            />
            <Button onClick={cbDownloadDataPressed}>Download Data</Button>

            <div id="download_spinner" class="spinner-border text-success d-inline-flex align-middle" role="status">
                <span class="sr-only">Loading...</span>
            </div>

        </div>
    );

    if (width > 520) {
        Row = (
            <div className="d-flex flex-row justify-content-between">
                {RowLeft}
                {RowMiddle}
                {RowRight}
            </div>
        );
    } else if (width > 380) {
        Row = (
            <div>
                <div className="center">{RowMiddle}</div>
                <div className="d-flex flex-row justify-content-between">
                    {RowLeft}
                    {RowRight}
                </div>
            </div>
        );
    } else {
        Row = (
            <div>
                <div className="center">{RowMiddle}</div>
                <div>
                    {RowLeft}
                </div>
                <div>
                    {RowRight}
                </div>
            </div>
        );
    }

    return (
        <div>{Row}</div>
    );
}