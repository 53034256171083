import React, { useState } from "react"
import "./DeviceDownloadData.css"
import { Button, InputGroup, FormControl } from "react-bootstrap"
import { useHelper } from "../contexts/HelperContext"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import firebase from "../firebase"
import readme from "../resources/readme.txt"


export default function DeviceDownloadData({onHide, sensorName, deviceId, dateStart, dateEnd}) {
    const [filename, setFilename] = useState(sensorName);
    const helperCtx = useHelper();

    function onChangeHandler(event) {
        setFilename(event.target.value);
    }

    function onSave() {
        if (filename == "") {
            alert("No filename given")
            onHide()
            return
        }
        if (dateEnd < dateStart) {
            alert("Download end date is before start date")
            onHide()
            return
        }

        if (dateEnd > dateStart + 604800 * 8) {
            console.log("Date range too high", dateEnd - dateStart)
            alert("Download limited to eight weeks")
            onHide()
            return
        }

        console.log(deviceId)
        console.log(dateStart)
        console.log(dateEnd)

        var dateStartAdjusted = dateStart - (60 * 60 * 24) // Subtract a day to ensure full data capture

        var channelsDocsRef = firebase.firestore().collection("sensors").doc(deviceId).collection("ch")
        .where('t0', '>=', dateStartAdjusted)
        .where('t0', '<', dateEnd)

        var metricsDocsRef = firebase.firestore().collection("sensors").doc(deviceId).collection("cache")
        .where('t0', '>=', dateStartAdjusted)
        .where('t0', '<', dateEnd)

        var dataDownload = {}

        document.getElementById("download_spinner").style.visibility = "visible"
        channelsDocsRef.get().then((querySnapshot) => {
            console.log("Got channel data for download")
            console.log(querySnapshot)
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                
                let docData = doc.data()
                console.log(doc.id, " => ", docData);
                for(let i = 0; i < docData['ts'].length; i++) {
                    dataDownload[docData['ts'][i]] = {
                        unixtime: docData['ts'][i],
                        f1: docData['f1'][i],
                        f2: docData['f2'][i],
                        f3: docData['f3'][i],
                        f4: docData['f4'][i],
                        f5: docData['f5'][i],
                        f6: docData['f6'][i],
                        f7: docData['f7'][i],
                        f8: docData['f8'][i],
                        clr: docData['clr'][i],
                        nir: docData['nir'][i],
                        X: docData['X'][i],
                        Y: docData['Y'][i],
                        Z: docData['Z'][i]
                    }
                    let metrics = helperCtx.get6Vars(
                        docData['ts'][i],
                        docData['f1'][i], docData['f2'][i], docData['f3'][i],
                        docData['f4'][i], docData['f5'][i], docData['f6'][i],
                        docData['f7'][i], docData['f8'][i], docData['clr'][i], docData['nir'][i],
                        docData['X'][i], docData['Y'][i], docData['Z'][i]
                    )
                    dataDownload[docData['ts'][i]]['lux'] = metrics['lux']
                    dataDownload[docData['ts'][i]]['cs'] = metrics['cs']
                    dataDownload[docData['ts'][i]]['medi'] = metrics['eml']*0.9058
                    dataDownload[docData['ts'][i]]['cct'] = metrics['cct']
                    dataDownload[docData['ts'][i]]['spd'] = metrics['spd']
                }
            });
             // Save off the data to a file!
            var zip = new JSZip();
            var spd_folder = zip.folder("spd")
            var raw_csv = ""; // Raw_csv is the metrics csv
            var spd_csv = "";
            var currentZone = helperCtx.getLocalTimezoneText();
            var vars;
            var local_date_time;
            var utc_date_time;


            raw_csv += sensorName + ',\n';
            raw_csv += 'date (utc), time (utc), date (' + currentZone + '), time (' + currentZone + '), lux, cct, cs, medi, X, Y, Z, f1, f2, f3, f4, f5, f6, f7, f8, clr, nir,\n';
            var count = 3;
            var keys = Object.keys(dataDownload)
            keys = keys.map(x => parseInt(x,10))
            keys.sort(function(a, b){return a - b});
            console.log("keys", keys) 

            for(let i = 0; i < keys.length; i ++) {
                let k = keys[i]
                let vars = dataDownload[k]
                utc_date_time = helperCtx.getUTCDateTextMilitary(k)
                local_date_time = helperCtx.getLocalDateTextMilitary(k);

                raw_csv += utc_date_time + ', ' + local_date_time + ',' + vars.lux + ', ' + vars.cct + ', ' + 
                vars.cs + ', ' + vars.medi + ', ' + vars.X + ', ' + vars.Y + ', ' + vars.Z + ', ' + 
                vars.f1 + ', ' + vars.f2 + ', ' + vars.f3 + ', ' + vars.f4 +  ', ' + 
                vars.f5 +  ', ' + vars.f6 +  ', ' + vars.f7 +  ', ' + vars.f8 +  ', ' + 
                vars.clr +  ', ' + vars.nir + ',\n';

                let spd_index = 400 
                let spd_data = vars.spd
                let spd_csv = ""
                for(let j = 0; j < spd_data.length; j++) {
                    spd_csv += spd_index + ',' + spd_data[j].toString() + "\n"
                    spd_index += 5
                }

                spd_csv = spd_csv.slice(0,spd_csv.length-1)
                spd_csv += ',\n';
                spd_folder.file("row_" + count.toString() + ".csv",spd_csv);
                ++count;
            }
        
            zip.file(sensorName + "_raw.csv", raw_csv);
            fetch(readme).then((result) => {
                result.blob().then((data) => {
                    zip.file("readme.txt", data);
                    zip.generateAsync({type:"blob"})
                        .then(function(content) {
                        saveAs(content, filename + ".zip"); // filesavor.js
                        document.getElementById("download_spinner").style.visibility = "hidden"
                    });
                });
            })
        })

        onHide()
    }

    return (
        <div>
            <div className="mdl">
                <div className="mdl-content">
                    <div className="mdl-header">
                        <h4 className="m-3 mdl-title">Save Data</h4>
                    </div>
                    <div className="mdl-body">
                        <InputGroup className="p-3">
                        <InputGroup.Text id="inputGroup-sizing-default">Filename</InputGroup.Text>
                        <FormControl onChange={onChangeHandler} value={filename} aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                        <InputGroup.Text>.zip</InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div className="mdl-footer d-flex">
                        <Button className="m-3" variant="secondary" onClick={onHide}>Cancel</Button>
                        <Button 
                            className="m-3 ms-auto" 
                            variant="primary" 
                            onClick={onSave}
                            disabled={filename === "" ? true : false}
                            >Save</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
