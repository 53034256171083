import React, { useRef, useState, useEffect } from "react"
import { useHelper } from "../contexts/HelperContext"
import { Scatter } from "react-chartjs-2"
import spectrum from "../400to700Spectrum.png"
import "rc-slider/assets/index.css"
import "./DeviceSpectrum.css"

export default function DeviceSpectrum({ts, spdData, cbChartArea, 
    useTimebar,
    boxsize,
    }) {
    const helperCtx = useHelper();

    const [chart, setChart] = useState(null);

    const spectrumTopRef = useRef(0);
    const spectrumHeightRef = useRef(0);
    const spectrumLeftRef = useRef(0);
    const spectrumWidthRef = useRef(0);
    const chartAreaRef = useRef(null);
    
    var ticks_font_size = 14;
    var title_font_size = 18;
    var graph_thickness = 4;

    var width = 1024;
    var box = document.querySelector('.container');
    if (box !== null) {
        width = box.offsetWidth;
        ticks_font_size = width * (.02);
        title_font_size = width * (.025);
        graph_thickness = width * (.005);
    }


    function graphData() {
        var ret = [];
        var idx = helperCtx.getIndexOfX(spdData, ts, "DeviceSpectrum.js line 38");
        if (idx >= 0 && idx < spdData.length) {
            for (var i = 0; i < spdData[idx].spd.length; i++) {
                // Truncate the value graphed at 0, but the datalog file will be the exact number
                // Is that ok?
                var yval = spdData[idx].spd[i];
                if (yval < 0) {
                    yval = 0;
                }
                ret.push({x: 400 + (i*5), y: yval});
            }
        }
        return ret;
    }

    function getMaxY() {
        var ret = 0;
        var idx = helperCtx.getIndexOfX(spdData, ts, "DeviceSpectrum.js line 55");
        if (idx >= 0 && idx < spdData.length) {
            for (var i = 0; i < spdData[idx].spd.length; i++) {
                var yval = spdData[idx].spd[i];
                if (ret < yval) {
                    ret = yval;
                }
            }
        }
        if (ret === 0)
            return 1;
        return (ret) * 1.05;
    }
    
    function graphColors() {
        var ret = [];
        var idx = helperCtx.getIndexOfX(spdData, ts, "DeviceSpectrum.js line 71");
        if (idx >= 0 && idx < spdData.length) {
            for (var i = 0; i < spdData[idx].spd.length; i++) {
                var wavelength = 400 + (i*5);
                var clr = helperCtx.getSpectrumColor(wavelength);
                ret.push(clr);
            }
        }
        return ret;
    }

    const chartData = {
        datasets: [
            {
                data: graphData(),
                showLine: true,
                borderColor: graphColors(), 
                pointRadius: graph_thickness / 2,
                pointHoverRadius: graph_thickness,
                borderWidth: graph_thickness,
                fill: false,
                yAxisID: 'yAxis',
                segment: {
                    borderColor: (ctx) => {
                        var i = ctx.p0DataIndex;
                        var wavelength = 400 + (i*5);
                        var clr = helperCtx.getSpectrumColor(wavelength);
                        return clr;
                    },
                  }
            },
        ],
    };
    
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0,
            onComplete: function() {
                if (chart != null) {
                    var chartArea = chart.chartArea;
                    var chartArea2 = chartAreaRef.current;
                    if (chartArea2 === null) {
                        chartAreaRef.current = chartArea;
                        cbChartArea(chartArea);
                    } else if (
                        (chartArea.left !== chartArea2.left) || 
                        (chartArea.right !== chartArea2.right) || 
                        (chartArea.width !== chartArea2.width) || 
                        (chartArea.top !== chartArea2.top) || 
                        (chartArea.bottom !== chartArea2.bottom) || 
                        (chartArea.height !== chartArea2.height)
                    ) { 
                        chartAreaRef.current = chartArea;
                        cbChartArea(chartArea);
                    }
                }    
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                position: 'bottom',
                display: true,
                text: 'Spectral Power Distribution',
                color: 'white',
                font: {
                    size: title_font_size,
                }                    
            },
        
            tooltip: {
                callbacks: {
                    label: function(context) {
                        var rawx = context.raw.x || '';
                        var rawy = context.raw.y || '';
                        return 'wavelength: ' + rawx + ' nm, power: ' + rawy + ' watts';
                    }
                }
            },
        },
        scales: {
            yAxis: {
                display: 'auto',
                min: 0,
                max: getMaxY(),
                title: {
                    display: true,
                    text: 'SPD (w)',
                    color: 'black',
                    font: {
                        size: title_font_size,
                    }                    
                },
                position: 'left',
                ticks: {
                    color: 'black',
                    font: {
                        size: ticks_font_size,
                    },
                    count: 10,
                },
                grid: { 
                    color: "black" 
                },
            },
            xAxis: {
                display: 'auto',
                min: 400,
                max: 700,
                title: {
                    display: true,
                    text: 'Wavelength (nm)                 ',
                    color: 'black',
                    font: {
                        size: title_font_size,
                    }
                },
                position: 'top',
                ticks: {
                    color: 'black',
                    font: {
                        size: ticks_font_size,
                    },
                    count: 10,
                    callback: (value) => { return Number(value).toFixed(0); },
                },
                grid: { 
                    color: "black" 
                },
            },
        },
    };

    function getStyle() {
        if (chart) {
            var xAxisPos = chart.scales.xAxis;
            spectrumTopRef.current =  chart.canvas.offsetTop + chart.titleBlock.top;
            spectrumLeftRef.current = chart.canvas.offsetLeft + xAxisPos.left-1;
            spectrumWidthRef.current = xAxisPos.width+2;
            spectrumHeightRef.current = chart.titleBlock.height;
        }

        return { 
            top: spectrumTopRef.current, 
            left: spectrumLeftRef.current, 
            width: spectrumWidthRef.current, 
            height: spectrumHeightRef.current 
        };  
    }


    const [stateCount, setStateCount] = useState(0);
    const stateCountRef = useRef(0);
    useEffect(() => {
        var interval = null;
        function handleResize() {
            if (interval != null) {
                clearInterval(interval);
                interval = null;
            }
            var icount = 20;
            interval = setInterval(() => {
                stateCountRef.current = stateCountRef.current + 1;
                setStateCount(stateCountRef.current);
                console.log (stateCountRef.current);
                if (--icount <= 0) {
                    clearInterval(interval);
                    interval = null;
                }
            }, 100);
        }
        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    }, [])


    function getScatterStyle() {
        // if we dont show the timer bar, then max the width and height
        // if (useTimebar === true) {
        //     var w, h;
        //     var box = document.querySelector('.scatterbox');
        //     if (box !== null) {
        //         w = box.offsetWidth;
        //         h = w * 1;
        //     }
        //     return {width: w, height: h};
        // }
        return {width: boxsize.width, height: boxsize.height - 20};
    }

    return (
        <div className="container scatterbox">
            <img src={spectrum} alt={stateCount} style={getStyle()} className="bmp" />
            <Scatter style={getScatterStyle()} data={chartData} options={chartOptions} ref={setChart}/>
        </div>
    );
}