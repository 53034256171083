import React, { useState, useEffect, useContext, useRef } from "react"
import firebase from "../firebase"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import { Dropdown, InputGroup, FormControl, Table, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faCheckCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import { faChevronCircleRight, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"
import { useHelper } from "../contexts/HelperContext"
import AdminContext from "../contexts/AdminContext"
import "./Admin.css"



export default function Admin() {
    const helperCtx = useHelper();
    const adminCtx = useContext(AdminContext);

    const { currentUser } = useAuth();
    const [devices, setDevices] = useState([]);
    const [getUIDInfo, setUIDInfo] = useState([]);
    const [getFilter, setFilter] = useState("");
    const gkeyRef = useRef(0);

    function updateDevices(id) {
        var newDevices = []
        var updatedDevices = []
        var removedDevices = []

        /* Process all sensor docs */
        function update_cache_list (doc, type) {
            var index = helperCtx.getIndexOfId(devices, id);
            if (type === 'added' || type === 'modified') {
                if (index >= 0) {
                    console.log("UpdateDevices: Modified Device")
                    console.log({
                        'id': doc.id,
                        'data': doc.data(),
                        'collapsed' : true,
                    })
                    updatedDevices.push({
                        'id': doc.id,
                        'data': doc.data(),
                        'collapsed' : true,
                    });
                } else {
                    newDevices.push({
                        'id': doc.id,
                        'data': doc.data(),
                        'collapsed' : true,
                    });
                }
            }
            else if (type === 'removed') {
                console.log("UpdateDevices: Removed Device")
                removedDevices.push(doc.id)
            }
        }

        /* Process the data from update_cache_list, trigger a rendering */
        function update_devices_state() {
            if (updatedDevices.length > 0) {
                console.log("UpdateDevices: Updated Devices")
                for(const ud of updatedDevices) {
                    console.log(ud)
                    const updatedMap = devices.map((c,i) => {
                        if(c.id === ud.id) {
                            return ud
                        }
                        else {
                            return c
                        }
                    })
                    setDevices(updatedMap)
                }
                console.log("updateDevices: Updated Devices Finished")
            }
            if (removedDevices.length > 0) {
                for(const rd of removedDevices) {
                    setDevices(devices.filter(d => d.id !== rd))
                }
            }
            if (newDevices.length > 0) {
                setDevices(devices => [...devices,...newDevices])
            }
        }

        console.log("get", id);
        if (id === "") {
            // get all documents
            console.log("updateDevices: Getting All Documents")
            firebase.firestore().collection("sensors").get().then((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    update_cache_list (change.doc, change.type);
                });
                update_devices_state()
                console.log("updateDevices: finished")
            });
        } else {
            // get one document
            console.log("updateDevices: Get one document")
            var getOptions = {
                source: 'default'
            };            
            firebase.firestore().collection("sensors").doc(id).get(getOptions).then((doc) => {
                console.log("UpdateDevice", id);
                update_cache_list (doc, "modified");
                update_devices_state()
            });
        }
        
    }

    useEffect(() => {
        // update the list with the current users
        // This should only run for the first render.
        console.log("Admin: UseEffect")
        const body = { command: 'getInfo', key: currentUser.uid};
        helperCtx.sendHttpRequest('post', body, (response) => {
            response.data.users.sort((a,b) => (a.email > b.email) ? 1:-1)
            console.log(response.data);
            console.log(response.data.users);
            setUIDInfo(response.data.users);
            updateDevices("");
        });
        console.log("useEffect: updateDevices Finished")
        return (() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // this itterates to full list and only displays whatever is in the filter section

    const List = ({ list }) => {
        var ret = [];
        var i;
        for (i = 0; i < list.length; i++)
        {
            var item = list[i];

            if (getFilter === "") {
                ret.push(<Item key={gkeyRef.current++} item={item} />);
            } else {
                var disp = false;
                if (item.data.hasOwnProperty('followers')) {
                    for (var j = 0; j < item.data.followers.length; j++) {
                        var uid = item.data.followers[j];
                        for (var k = 0; k < getUIDInfo.length; k++) {
                            var value = getUIDInfo[k];
                            if (uid === value.uid) {
                                if (value.email.toLowerCase().includes(getFilter.toLowerCase())) {
                                    disp = true;
                                } else if (value.display_name !== null) {
                                    if (value.display_name.toLowerCase().includes(getFilter.toLowerCase())) {
                                        disp = true;
                                    }
                                } 
                            }
                        }
                    }
                }
                if (helperCtx.translateId(item.id).toLowerCase().includes(getFilter.toLowerCase()) === true) {
                    disp = true;
                }
                if (disp === true) {
                    ret.push(<Item key={gkeyRef.current++} item={item} />);
                }
            }
        }

        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Device</th>
                            <th></th>
                            <th>E-mail</th>
                            <th>Name</th>
                            <th>CS</th>
                            <th>Battery</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ret}
                    </tbody>
                </Table>
            </div>
        );
    }

    const Item = ({ item }) => {
        const listElements = [];
        const lnk = (<Link to={"/device?id=" + item.id}>{helperCtx.translateId(item.id)}</Link>);
        var first = true;
        var delta = 0;
        var d = new Date();
        var epoch = Math.round(d.getTime() / 1000);

        // COLLAPSED CODE
        if (item.collapsed === true) {
            var vars3 = {
                id: item.id,
                link: lnk,
                icon:"",
                email: [], 
                name: [], 
                cs: "",
                volts: "",
            };

            if (item.data.hasOwnProperty('followers')) {
                item.data.followers.forEach((uid) => {
                    getUIDInfo.forEach((value) => {
                        if (uid === value.uid) {
                            if (first === true) {
                                first = false;
                            } else {
                                if (value.email !== null) {
                                    vars3.email.push(', ');
                                }
                                if (value.display_name !== null) {
                                    vars3.name.push(', ');
                                }
                            }
                            
                            if (value.email !== null) {
                                if (value.disabled === true) {
                                    vars3.email.push(<strike key={gkeyRef.current++}>{value.email}</strike>);
                                } else {
                                    vars3.email.push(value.email);
                                }
                            }
                            if (value.display_name !== null) {
                                if (value.disabled === true) {
                                    vars3.name.push(<strike key={gkeyRef.current++}>{value.display_name}</strike>);
                                } else {
                                    vars3.name.push(value.display_name);
                                }
                            }
                        }
                    });
                });
            }

            if (item.data.hasOwnProperty('c_cs')) {
                vars3.cs = item.data.c_cs.toFixed(2) + " cs";
                if (item.data.hasOwnProperty('c_cs_st')) {
                    delta = epoch - item.data.c_cs_st;
                    if (delta < (6 * 60)) {
                        vars3.cs = <div><FontAwesomeIcon style={{color: "green"}} icon={faCheckCircle}/>&nbsp;{vars3.cs}</div>;
                    } else {
                        vars3.cs = <div><FontAwesomeIcon style={{color: "red"}} icon={faTimesCircle}/>&nbsp;{vars3.cs}</div>;
                    }
                }
            }
            if (item.data.hasOwnProperty('c_volts')) {
                vars3.volts = item.data.c_volts.toFixed(2) + "v";
                if (item.data.hasOwnProperty('c_volts_st')) {
                    delta = epoch - item.data.c_volts_st;
                    if (delta < (20 * 60)) {
                        vars3.volts = <div><FontAwesomeIcon style={{color: "green"}} icon={faCheckCircle}/>&nbsp;{vars3.volts}</div>;
                    } else {
                        vars3.volts = <div><FontAwesomeIcon style={{color: "red"}} icon={faTimesCircle}/>&nbsp;{vars3.volts}</div>;
                    }                            
                }
            }

            vars3.icon = <FontAwesomeIcon icon={faChevronCircleRight} onClick={() => handleOnExpandClick(item)}/>;
            listElements.push ({key: gkeyRef.current++, data: <ChildItem vars={vars3} />});
        }

        // EXPANDED CODE
        if (item.collapsed === false) {
            var vars = {
                id: item.id,
                link: lnk,
                icon:"",
                email: "", 
                name: "", 
                cs: "",
                volts: "",
            };

            if (item.data.hasOwnProperty('c_cs')) {
                vars.cs = item.data.c_cs.toFixed(2) + " cs";
                if (item.data.hasOwnProperty('c_cs_st')) {
                    delta = epoch - item.data.c_cs_st;
                    if (delta < (6 * 60)) {
                        vars.cs = <div><FontAwesomeIcon style={{color: "green"}} icon={faCheckCircle}/>&nbsp;{vars.cs}</div>;
                    } else {
                        vars.cs = <div><FontAwesomeIcon style={{color: "red"}} icon={faTimesCircle}/>&nbsp;{vars.cs}</div>;
                    }
                }
            }
            if (item.data.hasOwnProperty('c_volts')) {
                vars.volts = item.data.c_volts.toFixed(2) + "v";
                if (item.data.hasOwnProperty('c_volts_st')) {
                    delta = epoch - item.data.c_volts_st;
                    if (delta < (20 * 60)) {
                        vars.volts = <div><FontAwesomeIcon style={{color: "green"}} icon={faCheckCircle}/>&nbsp;{vars.volts}</div>;
                    } else {
                        vars.volts = <div><FontAwesomeIcon style={{color: "red"}} icon={faTimesCircle}/>&nbsp;{vars.volts}</div>;
                    }                            
                }
            }

            vars.icon = <FontAwesomeIcon icon={faChevronCircleDown} onClick={() => handleOnCollapseClick(item)}/>;
            listElements.push ({data: <ChildItem vars={vars} />});


            if (item.data.hasOwnProperty('followers')) {
                item.data.followers.forEach((uid) => {
                    var vars1 = {
                        id: item.id,
                        link: "",
                        icon:"",
                        email: uid, 
                        name: "", 
                        cs: "",
                        volts: "",
                    };
            
                    getUIDInfo.forEach((value) => {
                        if (uid === value.uid) {
                            if (value.display_name !== null)
                                vars1.name = value.display_name;
                            if (value.email !== null)
                                vars1.email = value.email;
                        }
                    });

                    vars1.icon = <FontAwesomeIcon style={{color: "red"}} onClick={() => {handleOnClickRemoveUser(item.id, uid)}} icon={faMinusCircle}/>;
                    listElements.push ({data: <ExpandedChildItem vars={vars1} />});
                });
            }
            var vars2 = {
                id: "",
                link: "",
                icon:"",
                email: <UsernamesDropdown id={item.id} list={getUIDInfo}/>,
                name: "", 
                cs: "",
                volts: "",
            };

            listElements.push ({data: <ChildItem vars={vars2} />});
        }

        return (listElements.map((value, index) => {
            return (<tr key={gkeyRef.current++}>{value.data}</tr>);
        }));
    };

    const ChildItem = ({ vars }) => {
        return (
            <React.Fragment>
                <td>{vars.link}</td>
                <td>{vars.icon}</td>
                <td>{vars.email}</td>
                <td>{vars.name}</td>
                <td>{vars.cs}</td>
                <td>{vars.volts}</td>
            </React.Fragment>
        );
    }

    const getEditing = (email) => {
        for (var i = 0; i < getUIDInfo.length; ++i) {
            if (getUIDInfo[i].email === email) {
                if (getUIDInfo[i].editing === null) {
                    return false;
                }
                return getUIDInfo[i].editing;
            }
        }
        return false;
    }

    const setEditing = (email, value) => {
        for (var i = 0; i < getUIDInfo.length; ++i) {
            if (getUIDInfo[i].email === email) {
                getUIDInfo[i].editing = value;
                setUIDInfo([...getUIDInfo]);
                break;
            }
        }
    }

    const getDisabled = (email) => {
        for (var i = 0; i < getUIDInfo.length; ++i) {
            if (getUIDInfo[i].email === email) {
                if (getUIDInfo[i].disabled === null) {
                    return false;
                }
                return getUIDInfo[i].disabled;
            }
        }
        return false;
    }

    const setDisabled = (email, value) => {
        for (var i = 0; i < getUIDInfo.length; ++i) {
            if (getUIDInfo[i].email === email) {
                getUIDInfo[i].disabled = value;
                setUIDInfo([...getUIDInfo]);
                break;
            }
        }
    }


    const ExpandedChildItem = ({ vars }) => {
        var disabledText = getDisabled(vars.email) ? 'Disabled' : 'Enabled';
        var disabledColor = getDisabled(vars.email) ? "btn-danger": "btn-success" ;

        var editText = getEditing(vars.email)  ? 'Commit' : 'Edit';
        var displayNameDisabledText = <strike>{vars.name}</strike>;
        var displayNameEnabledText = vars.name;
        var displayEmailDisabledText = <strike>{vars.email}</strike>;
        var displayEmailEnabledText = vars.email;

        var myuid = (emailToUID(vars.email) === currentUser.uid);
        var editDisplayNameText = (<InputGroup className="mb-3">
        <FormControl autoFocus='True' value={vars.name} onChange={(event) => {handleOnChange(event, vars.email);}} aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
        </InputGroup>);
        return (<React.Fragment>
            <td>{vars.link}</td>
            <td>{vars.icon}</td>
            <td>{getDisabled(vars.email) ? displayEmailDisabledText : displayEmailEnabledText}</td>
            <td>
                <Button onClick={() => {
                    if (getEditing(vars.email) === true) {
                        setEditing(vars.email, false);
                        // commit the displayname
                        var uid = emailToUID(vars.email);
                        const body = { command: 'changeDisplayName', key: currentUser.uid, displayName: vars.name, uid: uid};
                        helperCtx.sendHttpRequest('post', body, (response) => {
                            if (response.data.result === true) {
                                if (myuid) {
                                    adminCtx.setDisplayName(vars.name); // update the banner display name
                                }
                            }
                        });                
                    } else {
                        setEditing(vars.email, true);
                    }
                 }}>{editText}</Button> &nbsp;
 
                 
                {getEditing(vars.email) ? editDisplayNameText : getDisabled(vars.email) ? displayNameDisabledText : displayNameEnabledText}

                &nbsp;

                {!myuid && <Button className={disabledColor} onClick={() => {
                    var disabled = !getDisabled(vars.email);
                    setDisabled(vars.email, disabled); // invert the user
                    // commit the enable/disable
                    var uid = emailToUID(vars.email);
                    const body = { command: 'disableUser', key: currentUser.uid, disabled: disabled, uid: uid};
                    helperCtx.sendHttpRequest('post', body, (response) => {
                    });                
             }}>{disabledText}</Button>}
            </td>
            <td>{vars.cs}</td>
            <td>{vars.volts}</td>
            </React.Fragment>);
    }

    function handleOnChange(event, email) {
        var displayName = event.target.value;
        var uidInfo = getUIDInfo;
        for (var i = 0; i < uidInfo.length; ++i) {
            if (uidInfo[i].email === email) {
                uidInfo[i].display_name = displayName;
                setUIDInfo([...uidInfo]);
                break;
            }
        }
    }

    function emailToUID(email) {
        for (var i = 0; i < getUIDInfo.length; ++i) {
            if (getUIDInfo[i].email === email) {
                return getUIDInfo[i].uid;
            }
        }
        return email;
    }

    function handleOnExpandClick(item) {
        var index = helperCtx.getIndexOfId(devices, item.id);
        if (index >= 0) {
            devices[index].collapsed = false;
            setDevices([...devices]);
        }
    }

    function handleOnCollapseClick(item) {
        var index = helperCtx.getIndexOfId(devices, item.id);
        if (index >= 0) {
            devices[index].collapsed = true;
            setDevices([...devices]);
        }        
    }

    // Fix these functions to use the cloud
    function handleOnClickAddUser(id, uid) {
        var body = { command: 'addUser', key: currentUser.uid, id: id, uid: uid};
        helperCtx.sendHttpRequest('post', body, (response) => {
            updateDevices(id);
        });                
    }

    function handleOnClickRemoveUser(id, uid) {
        var body = { command: 'removeUser', key: currentUser.uid, id: id, uid: uid};
        helperCtx.sendHttpRequest('post', body, (response) => {
            updateDevices(id);
        });                
    }

    function handleFilterOnChange(event) {
        setFilter(event.target.value);
    }
    
    const UsernamesDropdownItem = ({id, item}) => {
        return (<Dropdown.Item onClick={() => {handleOnClickAddUser(id, item.uid)}}>{item.email}, {item.display_name}</Dropdown.Item>);
    }


    const UsernamesDropdown = ({id, list}) => {
        var ret = [];

        // to do, remove the followers already in the list.
        list.forEach((item) => {
            ret.push(<UsernamesDropdownItem key={item.uid} item={item} id={id}/>);
        });
        
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    select a user
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {ret}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (<div className="admin container">
                <div className="mt-3">
                    <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text>Filter</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl onChange={handleFilterOnChange} aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
                    </InputGroup>
                </div>
                <List className="admin" list={devices}/>
            </div>);
}

