import React, { useRef } from "react"
import { useHelper } from "../contexts/HelperContext"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import "./DeviceGraphSliderWithButtons.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

export default function DeviceGraphSliderWithButtons(
    {   
        value, onChange,
        showGraph, spdData, 
        luxHidden, csHidden, mediHidden, cctHidden,
        luxOnClick, csOnClick, mediOnClick, cctOnClick,
        chartArea
    }) {
    const helperCtx = useHelper();
    const sliderRef = useRef(null);

    function handleLessThanOnClick() {
        if (value > 0) {
            onChange(value - 1);
        }
    }

    function handleGreaterThanOnClick() {
        if (value < (spdData.length-1)) {
            onChange(value + 1);
        }
    }  

    function getTs() {
        var ret = "";
        if (spdData.length > 1) {
            if (value >= 0 && value < spdData.length) {
                if (spdData[value] !== undefined) {
                    var epoch = spdData[value].x;
                    ret = helperCtx.getLocalDateText(epoch) + ' (' + helperCtx.getLocalTimezoneText() + ')';
                }
            }
        }
        return ret;
    }

    function getLUX() {
        var ret = "LUX -.------";
        if (spdData.length > 1) {
            if (value => 0 && value < spdData.length) {
                if (spdData[value] !== undefined) {
                    var val = spdData[value].lux.toFixed(3);
                    if (val > 0) {
                        ret = "LUX " + val;
                    }
                }
            }
        }
        return ret;
    }

    function getCS() {
        var ret = "CS -.------";
        if (spdData.length > 1) {
            if (value => 0 && value < spdData.length) {
                if (spdData[value] !== undefined) {
                    var val = spdData[value].cs.toFixed(5);
                    if (val > 0) {
                        ret = "CS " + val;
                    }
                }
            }
        }
        return ret;
    }

    function getMEDI() {
        var ret = "M-EDI -.------";
        if (spdData.length > 1) {
            if (value => 0 && value < spdData.length) {
                if (spdData[value] !== undefined) {
                    var val = spdData[value].eml;
                    if (val > 0) {
                        ret = "M-EDI " + (val*0.9058).toFixed(3);
                    }
                }
            }
        }
        return ret;
    }


    function getCCT() {
        var ret = "CCT -.------";
        if (spdData.length > 1) {
            if (value => 0 && value < spdData.length) {
                if (spdData[value] !== undefined) {
                    var val = spdData[value].cct.toFixed(3);
                    if (val > 0) {
                        ret = "CCT " + val;
                    }
                }
            }
        }
        return ret;
    }

    var classLux = "nowrap clr1";
    var classCS = "nowrap clr2";
    var classMEDI = "nowrap clr3";
    var classCCT = "nowrap clr4";


    if (luxHidden || showGraph !== 0) {
        classLux = "nowrap clr0";
    }
    if (csHidden || showGraph !== 0) {
        classCS = "nowrap clr0";
    }
    if (mediHidden || showGraph !== 0) {
        classMEDI = "nowrap clr0";
    }
    if (cctHidden || showGraph !== 0) {
        classCCT = "nowrap clr0";
    }


    const trackStyle = {
        backgroundColor: '#158cba', height: 5
    }
    const railStyle = {
        backgroundColor: '#158cba', height: 5
    }

    const textRef = useRef(null);

    var width = 1024;
    var textFontSize = 24;
    var displayStyle = {fontSize: 24, color: '#FFFFFF', padding: 10, margin: 10, borderRadius: 10};
    var box = document.querySelector('.container');
    if (box !== null) {
        width = box.offsetWidth;
        textFontSize = width * (.015);
        displayStyle.fontSize = width * (.015);
        displayStyle.padding = width * (.009);
        displayStyle.margin = width * (.006);
        displayStyle.borderRadius = width * (.01);
    }

    return (
        <div className="container">
            <div className="d-flex justify-content-center">
                <div className={classLux} style={displayStyle} onClick={luxOnClick}>{getLUX()}</div>
                <div className={classCS}  style={displayStyle} onClick={csOnClick}>{getCS()}</div>
                <div className={classMEDI} style={displayStyle} onClick={mediOnClick}>{getMEDI()}</div>
                <div className={classCCT} style={displayStyle} onClick={cctOnClick}>{getCCT()}</div>
            </div>

            <div ref={textRef} style={{fontSize: textFontSize, color: 0}}  className="text-center">{getTs()}</div>

            <div className="d-flex cslider">
                <div className="d-flex" style={{width: chartArea.left}}/>
                <div className="d-flex flex-grow-1">
                    <FontAwesomeIcon 
                    onClick={handleLessThanOnClick} 
                    className="fa arrows" 
                    icon={faChevronLeft}
                    />
                    &nbsp;&nbsp;
                    <Slider className="mt-2" 
                        value={value}
                        onChange={onChange}
                        min={0} 
                        max={spdData.length - 1} 
                        ref={sliderRef} 
                        trackStyle={trackStyle}
                        railStyle={railStyle}
                        />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon 
                    onClick={handleGreaterThanOnClick} 
                    className="fa arrows" 
                    icon={faChevronRight}/>
                </div>
            </div>
        </div>
    );
}