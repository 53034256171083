import { useState, useRef } from "react"
import { useHelper } from "../contexts/HelperContext"
import { Scatter } from "react-chartjs-2"
import "./DeviceGraph.css"

export default function DeviceGraph({
        ts, spdData,  
        luxHidden, csHidden, mediHidden, cctHidden,
        luxHiddenOnChange, csHiddenOnChange, mediHiddenOnChange, cctHiddenOnChange,
        cbChartArea,  useTimebar, boxsize
    }) {

    const helperCtx = useHelper();

    const color_red = 'rgba(180, 0, 0, 1)';
    const color_green = 'rgba(0, 128, 0, 1)';
    const color_blue = 'rgba(0, 0, 255, 1)';
    const color_gray = '#158cba';
    const color_orange = 'rgba(255, 153, 27, 1)';

    const [chart, setChart] = useState(null);
    const chartAreaRef = useRef(null);

    var ticks_font_size = 14;
    var title_font_size = 18;

    var width = 1024;
    var box = document.querySelector('.container');
    if (box !== null) {
        width = box.offsetWidth;
        ticks_font_size = width * (.02);
        title_font_size = width * (.025);
    }

    function getMinTS() {
        if (spdData.length > 1) {
            var delta = spdData[spdData.length-1].x - spdData[0].x;
            var buffer = 0;
            if (delta < 4000) // delta 1 hr = 3540
                buffer = 8;
            else if (delta < 8000) // delta 2 hr = 7140
                buffer = 18;
            else if (delta < 11000) // delta 3 hr = 10740
                buffer = 28;
            else if (delta < 22000) // delta 6 hr = 21340
                buffer = 50;
            else if (delta < 44000) // delta 12 hr = 42898
                buffer = 100;
            else if (delta < 90000) // delta 24 hr = 85497
                buffer = 195;
            else if (delta < 180000) // delta 48 hr = 171894
                buffer = 380;
            else if (delta < 352000) // delta 96 hr = 343790
                buffer = 580;
            return spdData[0].x - buffer;
        }
        return 0;
    }

    function getMaxTS() {
        if (spdData.length > 1) {
            var delta = spdData[spdData.length-1].x - spdData[0].x;
            var buffer = 0;
            if (delta < 4000) // delta 1 hr = 3540
                buffer = 8;
            else if (delta < 8000) // delta 2 hr = 7140
                buffer = 18;
            else if (delta < 11000) // delta 3 hr = 10740
                buffer = 28;
            else if (delta < 22000) // delta 6 hr = 21340
                buffer = 50;
            else if (delta < 44000) // delta 12 hr = 42898
                buffer = 100;
            else if (delta < 90000) // delta 24 hr = 85497
                buffer = 195;
            else if (delta < 180000) // delta 48 hr = 171894
                buffer = 380;
            else if (delta < 352000) // delta 96 hr = 343790
                buffer = 580;
            return spdData[spdData.length-1].x + buffer;
        }
        return 1;
    }

    function getTSData() {
        var ret = [];
        ret.push({x: ts, y: 0});
        ret.push({x: ts, y: 1});
        return ret;
    }

    function getLUX() {
        var ret = [];
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; ++i) {
                ret.push({x: spdData[i].x, y: spdData[i].lux});
            }
        }
        return ret;
    }

    function getCS() {
        var ret = [];
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; ++i) {
                ret.push({x: spdData[i].x, y: spdData[i].cs});
            }
        }
        return ret;
    }

    function getMEDI() {
        var ret = [];
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; ++i) {
                ret.push({x: spdData[i].x, y: spdData[i].eml*0.9058});
            }
        }
        return ret;
    }

    function getCCT() {
        var ret = [];
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; ++i) {
                ret.push({x: spdData[i].x, y: spdData[i].cct});
            }
        }
        return ret;
    }

    function getMaxYLUX() {
        var ret = 0;
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; i++) {
                var yval = spdData[i].lux;
                if (ret < yval) {
                    ret = yval;
                }
            }
        }
        return ret *= 1.05;
    }
    function getMaxYCS() {
        var ret = 0;
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; i++) {
                var yval = spdData[i].cs;
                if (ret < yval) {
                    ret = yval;
                }
            }
        }
        return ret *= 1.05;
    }
    function getMaxYMEDI() {
        var ret = 0;
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; i++) {
                var yval = spdData[i].eml*0.9058;
                if (ret < yval) {
                    ret = yval;
                }
            }
        }
        return ret *= 1.05;
    }
    function getMaxYCCT() {
        var ret = 0;
        if (spdData.length > 1) {
            for (var i = 0; i < spdData.length; i++) {
                var yval = spdData[i].cct;
                if (ret < yval) {
                    ret = yval;
                }
            }
        }
        return ret *= 1.05;
    }


    const chartData = {
        datasets: [ 
            { 
                hidden: luxHidden,
                yAxisID: 'yAxisLUX',
                label: 'LUX', 
                data: getLUX(),  
                backgroundColor: color_red, 
                borderColor: color_red, 
                showLine: true,
                pointRadius: 2,
                pointHoverRadius: 4,
            },
            { 
                hidden: csHidden,
                yAxisID: 'yAxisCS',
                label: 'CS', 
                data: getCS(),    
                backgroundColor: color_green, 
                borderColor: color_green, 
                showLine: true,
                pointRadius: 2,
                pointHoverRadius: 4,
            },
            { 
                hidden: mediHidden,
                yAxisID: 'yAxisMEDI',
                label: 'MEDI', 
                data: getMEDI(),  
                backgroundColor: color_blue, 
                borderColor: color_blue, 
                showLine: true,
                pointRadius: 2,
                pointHoverRadius: 4,
            },
            { 
                hidden: cctHidden,
                yAxisID: 'yAxisCCT',
                label: 'CCT', 
                data: getCCT(),  
                backgroundColor: color_orange, 
                borderColor: color_orange, 
                showLine: true,
                pointRadius: 2,
                pointHoverRadius: 4,
            },
            { 
                yAxisID: 'yAxisTS',
                label: 'TS',
                data: getTSData(),  
                backgroundColor: color_gray, 
                borderColor: color_gray, 
                showLine: useTimebar,
                pointRadius: useTimebar ? 2 : 0,
                pointHoverRadius: useTimebar ? 2 : 0,
            },
        ],
    }

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0,
            onComplete: function() {
                if (chart != null) {
                    var chartArea = chart.chartArea;
                    var chartArea2 = chartAreaRef.current;
                    if (chartArea2 === null) {
                        chartAreaRef.current = chartArea;
                        cbChartArea(chartArea);
                    } else if (
                        (chartArea.left !== chartArea2.left) || 
                        (chartArea.right !== chartArea2.right) || 
                        (chartArea.width !== chartArea2.width) || 
                        (chartArea.top !== chartArea2.top) || 
                        (chartArea.bottom !== chartArea2.bottom) || 
                        (chartArea.height !== chartArea2.height)
                    ) { 
                        chartAreaRef.current = chartArea;
                        cbChartArea(chartArea);
                    }
                }    
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        var label = context.dataset.label || '';
                        var rawx = context.raw.x || '';
                        var rawy = context.raw.y || '';
                        var simpledate = helperCtx.getLocalTimeText(rawx);
                        simpledate += '    ' + label + '    ' + rawy;
                        if (rawy === "")
                            simpledate += '* missing data point';
                        return simpledate;
                    }
                }
            },
            legend: {
                display: false,
                labels: {
                    filter: function (item, chart) {
                        return !item.text.includes('TS'); // remove TS from the chart legend
                    },
                },
                onClick: function (e, legendItem) {
                    var index = legendItem.datasetIndex;
                    if (index === 0) {
                        luxHiddenOnChange(!chartData.datasets[index].hidden);
                    } else if (index === 1) {
                        csHiddenOnChange(!chartData.datasets[index].hidden);
                    } else if (index === 2) {
                        mediHiddenOnChange(!chartData.datasets[index].hidden);
                    } else if (index === 3) {
                        cctHiddenOnChange(!chartData.datasets[index].hidden);
                    }
                },
            },
        },
        scales: {
            yAxisLUX: {
                display: 'auto',
                title: {
                    display: true,
                    text: 'lux',
                    color: color_red,
                    font: {
                        size: title_font_size,
                    }
                },
                position: 'left',
                ticks: {
                    color: color_red,
                    count: 10,
                    font: {
                        size: ticks_font_size,
                    }
                },
                min: 0,
                max: getMaxYLUX(),
            },
            yAxisCS: {
                display: 'auto',
                title: {
                    display: true,
                    text: 'cs',
                    color: color_green,
                    font: {
                        size: title_font_size,
                    }
                },
                position: 'left',
                ticks: {
                    color: color_green,
                    count: 10,
                    font: {
                        size: ticks_font_size,
                    }
                },
                min: 0,
                max: getMaxYCS(),
            },
            yAxisMEDI: {
                display: 'auto',
                title: {
                    display: true,
                    text: 'medi',
                    color: color_blue,
                    font: {
                        size: title_font_size,
                    }
                },
                position: 'left',
                ticks: {
                    color: color_blue,
                    count: 10,
                    font: {
                        size: ticks_font_size,
                    }
                },
                min: 0,
                max: getMaxYMEDI(),
            },
            yAxisCCT: {
                display: 'auto',
                title: {
                    display: true,
                    text: 'cct',
                    color: color_orange,
                    font: {
                        size: title_font_size,
                    }
                },
                position: 'left',
                ticks: {
                    color: color_orange,
                    count: 10,
                    font: {
                        size: ticks_font_size,
                    }
                },
                min: 0,
                max: getMaxYCCT(),
            },
            yAxisTS: {
                display: false,
                min: 0.1,
                max: 0.9,
            },
            xAxis: {
                scaleLabel: {
                    display: true, 
                    labelString: 'time',
                    font: {
                        size: title_font_size,
                    }
                },
                min: getMinTS(),
                max: getMaxTS(),
                ticks : {
                    callback: function(value, index, values) {
                        if (value <= 1) {
                            return "";
                        }
                        return helperCtx.getLocalTimeText(value);
                    },
                    count: 10,
                    minRotation: 20,
                    font: {
                        size: ticks_font_size,
                    }
                },
            }
        },
    };

    function getStyle() {
        // if we dont show the timer bar, then max the width and height
        //if (useTimebar === true)
        //    return {};
        return {width: boxsize.width, height: boxsize.height - 20};
    }

    return (
        <div className="container bgred">
            <Scatter style={getStyle()} data={chartData} options={chartOptions} ref={setChart}/>
        </div>
    );
}