import React, { useState, useEffect } from "react"
import { useHelper } from "../contexts/HelperContext"
import "./DevicePageButtons.css"

export default function DevicePageButtons({   
        ts, showGraph, spdData, 
        luxHidden, csHidden, emlHidden, cctHidden,
        luxOnClick, csOnClick, emlOnClick, cctOnClick,
        getBoxSize, setBoxSize
    })
    {
    
    const helperCtx = useHelper();
    var index = helperCtx.getIndexOfX(spdData, ts, "DevicePageButtons.js line 14");
    const [redrawCount, setRedrawCount] = useState(0);

    // EFFECT START
    useEffect(() => {
        setRedrawCount(redrawCount + 1);
        function handleResize() {
            setRedrawCount(redrawCount + 1);
        }
        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // EFFECT STOP


    function getLUX() {
        var ret = "LUX -.------";
        if (spdData.length > 1) {
            if (index => 0 && index < spdData.length) {
                if (spdData[index] !== undefined) {
                    var val = spdData[index].lux.toFixed(3);
                    if (val > 0) {
                        ret = "LUX " + val;
                    }
                }
            }
        }
        return ret;
    }

    function getCS() {
        var ret = "CS -.------";
        if (spdData.length > 1) {
            if (index => 0 && index < spdData.length) {
                if (spdData[index] !== undefined) {
                    var val = spdData[index].cs.toFixed(5);
                    if (val > 0) {
                        ret = "CS " + val;
                    }
                }
            }
        }
        return ret;
    }

    function getEML() {
        var ret = "EML -.------";
        if (spdData.length > 1) {
            if (index => 0 && index < spdData.length) {
                if (spdData[index] !== undefined) {
                    var val = spdData[index].eml.toFixed(3);
                    if (val > 0) {
                        ret = "EML " + val;
                    }
                }
            }
        }
        return ret;
    }

    function getCCT() {
        var ret = "CCT -.------";
        if (spdData.length > 1) {
            if (index => 0 && index < spdData.length) {
                if (spdData[index] !== undefined) {
                    var val = spdData[index].cct.toFixed(3);
                    if (val > 0) {
                        ret = "CCT " + val;
                    }
                }
            }
        }
        return ret;
    }

    var classLux = "nowrap clr1";
    var classCS = "nowrap clr2";
    var classEML = "nowrap clr3";
    var classCCT = "nowrap clr4";

    if (luxHidden || showGraph !== 0) {
        classLux = "nowrap clr0";
    }
    if (csHidden || showGraph !== 0) {
        classCS = "nowrap clr0";
    }
    if (emlHidden || showGraph !== 0) {
        classEML = "nowrap clr0";
    }
    if (cctHidden || showGraph !== 0) {
        classCCT = "nowrap clr0";
    }

   function displayStyle(){
        var ret = {fontSize: 20, color: '#FFFFFF', padding: 10, margin: 10, borderRadius: 10};
        var box = document.querySelector('.boxdpb');
        if (box !== null) {
            var width = box.offsetWidth;
            ret.fontSize = width * (.019);
            ret.padding = width * (.009);
            ret.margin = width * (.006);
            ret.borderRadius = width * (.01);
            if (getBoxSize.width !== box.offsetWidth || getBoxSize.height !== box.offsetHeight){
                setBoxSize({width: box.offsetWidth, height: box.offsetHeight})
            }
        }

    return ret;
   }

    return (
        <div
        className="container boxdpb d-flex justify-content-center">
            <div className={classLux} style={displayStyle()} onClick={luxOnClick}>{getLUX()}</div>
            <div className={classCS}  style={displayStyle()} onClick={csOnClick}>{getCS()}</div>
            <div className={classEML} style={displayStyle()} onClick={emlOnClick}>{getEML()}</div>
            <div className={classCCT} style={displayStyle()} onClick={cctOnClick}>{getCCT()}</div>
        </div>
    );
}