import React, { useEffect, useState, useRef } from "react"
import "./DeviceMetaInput.css"
import { Button, InputGroup, FormControl, DropdownButton } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import firebase from "../firebase"
import { useHelper } from "../contexts/HelperContext"
import { useAuth } from "../contexts/AuthContext"

export default function DeviceMetaInput({onHide, deviceIdArray, metaDisplay}) {
    const helperCtx = useHelper();
    const { currentUser } = useAuth();

    var meta = helperCtx.initalMetaArray();

    meta.forEach((item) => {
        if (metaDisplay === "") {
            item.show = true;
        } else if (metaDisplay === item.metaname) {
            item.show = true;
        }
    });

    const metaArrayValRef = useRef([]);
    var metatemplate = {
        name: "",
        project: "",
        address: "",
        building: "",
        room: "",
        cardinal_direction: "",
    };
    const [metaVal, setMetaVal] = useState(metatemplate);

    useEffect(() => {
        metaArrayValRef.current = [];
        var i = 0;
        console.log("deviceIdArray", deviceIdArray);
        for (i = 0; i < deviceIdArray.length; i++) {
            firebase.firestore().collection("sensors").doc(deviceIdArray[i]).get()
            .then((doc) => {
                var metadata = {
                    id: "",
                    name: "",
                    project: "",
                    address: "",
                    building: "",
                    room: "",
                    cardinal_direction: "",
                };
            
                if (doc.exists) {
                    var jdata = doc.data();
                    if (jdata.hasOwnProperty("meta") === true) {
                        for (var metaattr in metadata) { // enumerate its property names
                            if (jdata["meta"].hasOwnProperty(metaattr)) {
                                metadata[metaattr] = jdata["meta"][metaattr]
                                if (doc.id === deviceIdArray[0]) {
                                    metaVal[metaattr] = jdata["meta"][metaattr];
                                    setMetaVal(JSON.parse(JSON.stringify(metaVal)));
                                }
                            }
                        }
                    }
                    metadata["id"] = doc.id;
                    metaArrayValRef.current.push(metadata);
                }
            });    
        }
        return (() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
    useEffect(() => {
        firebase.firestore().collection("sensors").doc(deviceIdArray[0]).get()
        .then((doc) => {
            if (doc.exists) {
                var jdata = doc.data();
                if (jdata.hasOwnProperty("meta") === true) {
                    meta.forEach((value)=> {
                        if (jdata["meta"].hasOwnProperty(value.metaname)) {
                            metaVal[value.metaname] = jdata["meta"][value.metaname];
                            setMetaVal(JSON.parse(JSON.stringify(metaVal)));
                        }    
                    })
                }
            }
        });
        return (() => {});
    }, []);
    */

    function setMetaData(attr, value) {
        //console.log ("attr", attr, "value", value);
        metaVal[attr] = value;
        setMetaVal(JSON.parse(JSON.stringify(metaVal)));
    }

    function onSave() {

        var i = 0;
        for (i = 0; i < metaArrayValRef.current.length; i++) 
        {
            if (metaDisplay !== "") {
                var body;
                var saveMeta = {};
                for (var attr in metaArrayValRef.current[i]) {
                    if (attr !== "id" && attr !== metaDisplay) {
                        saveMeta[attr] = metaArrayValRef.current[i][attr];
                    } else if (attr !== "id") {
                        saveMeta[attr] = metaVal[attr];
                    }
                }
                console.log("saveMeta", saveMeta, "id",  metaArrayValRef.current[i].id);
                body = { command: 'updateMeta', id: metaArrayValRef.current[i].id, uid: currentUser.uid, meta: saveMeta};
                helperCtx.sendHttpRequest('post', body, (response) => {
                    console.log(response.data);
                });    
            } else {
                console.log("save meta", metaVal);
                body = { command: 'updateMeta', id: metaArrayValRef.current[i].id, uid: currentUser.uid, meta: metaVal};
                helperCtx.sendHttpRequest('post', body, (response) => {
                    console.log(response.data);
                });    
            }
        }

        onHide();
    }

    function AllMetaSuggestions({suggestions}) {
        if (suggestions.length === 0) {
            return <div/>;
        }
        return (
            <DropdownButton title="">
            {
                suggestions.map((value) => {
                    return (<DropdownItem onClick={()=>{setMetaData('cardinal_direction',value)}}>{value}</DropdownItem>);
                })
            }
            </DropdownButton>
        );
    }

    return (
        <div>
            <div className="mdl">
                <div className="mdl-content">
                    <div className="mdl-header">
                        <h4 className="m-3 mdl-title">Sensor Metadata</h4>
                    </div>
                    <div className="mdl-body">
                        {
                            meta.map((value, index) => {
                                if (value.show === false) {
                                    return (<div></div>);
                                }
                                return (
                                    <div>
                                    <InputGroup className="p-3" component={index}>
                                    <InputGroup.Text id="inputGroup-sizing-default">{value.displayname}</InputGroup.Text>
                                    <FormControl 
                                        onChange={(event)=>{setMetaData(value.metaname, event.target.value)}}
                                        value={metaVal[value.metaname]}
                                        aria-label="Default" 
                                        aria-describedby="inputGroup-sizing-default" 
                                    />
                                    <AllMetaSuggestions suggestions={value.suggestions}/>
                                    </InputGroup>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="mdl-footer d-flex">
                        <Button className="m-3" variant="secondary" onClick={onHide}>Cancel</Button>
                        <Button className="m-3 ms-auto" variant="primary" onClick={onSave}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
