import React, {useState, useEffect, useRef} from "react"
import DeviceSpectrum from "../components/DeviceSpectrum"
import DeviceGraph from "../components/DeviceGraph"
import DevicePageButtons from "./DevicePageButtons"
import { Spinner } from "react-bootstrap"
import firebase from "../firebase"
import { useHelper } from "../contexts/HelperContext"
import "./DevicePage.css"


export default function DevicePage({
    boxsize,
    sensors, 
    sensorId, 
    dropdown,
    getGraphView, 
    getTimeDivision, 
    getTimeRange, 
    dateValueRef, 
    timeRangeRef, 
    setStartAndEndDateTime,
    dateStartRef,
    dateEndRef,
    ts,
}) {

    function getSensorText(id) {
        var sensor = undefined;
        sensors.forEach((item) => {
            if (item.id === id) {
                sensor = item;
            }
        });

        if (sensor === undefined)
            return "";
        if (sensor.hasOwnProperty('data')) {
            if (sensor.data.hasOwnProperty('meta')) {
                if (sensor.data.meta.hasOwnProperty('name')) {
                    if (sensor.data.meta.name !== "") {
                        return sensor.data.meta.name;
                    }
                }                    
            }                
        }
        return helperCtx.translateId(id);
    }

    const [hideSpinnerCount, setHideSpinnerCount] = useState(0);
    const [csHiddenAttr, setCsHiddenAttr] = useState(false);
    const [luxHiddenAttr, setLuxHiddenAttr] = useState(true);
    const [emlHiddenAttr, setEmlHiddenAttr] = useState(true);
    const [cctHiddenAttr, setCctHiddenAttr] = useState(true);

    const bucketRef = useRef([]);
    const spdDataRef = useRef([]);
    const spdDataFilteredRef = useRef([]);

    var box1wh = {width: 0, height: 0};
    var box2whRef = useRef({width: 0, height: 0});

    var box1 = document.querySelector('.box1');
    if (box1 !== null) {
        box1wh.width = box1.offsetWidth;
        box1wh.height = box1.offsetHeight;
    }
    
    if (getGraphView === 1) { 
        // spectrum does not show buttons
        box2whRef.current = {width: 0, height: 0};
    }
    var box3 = {width: 0, height: 0};
    box3.width = boxsize.width;
    box3.height = boxsize.height - box1wh.height - box2whRef.current.height;

    const time1Ref = useRef(0);
    const time2Ref = useRef(0);

    const helperCtx = useHelper();
    
    // EFFECT START
    useEffect(() => {
        var unsubscribeFromDB = null;

        if (sensorId !== "") {
            // Decode the date selected
            var selectedDateStart = Date.parse(dateValueRef.current) / 1000;

            var selectedDateQueryStart;
            var selectedDateQueryEnd;

            selectedDateQueryEnd = selectedDateStart + (60 * 60 * 24);
            selectedDateQueryStart = selectedDateStart - (60 * 60 * 24) - (60 * 60 * timeRangeRef.current) ;
            setStartAndEndDateTime(sensorId, "4");

            // Subscribe to the database for the selected time

            // unscubscribeFromDB is the compliment function to the document subscribe statement.
            // The useEffect() statement runs the body of the function and waits for getDateValue to 
            // change before redrawing.
            unsubscribeFromDB = firebase.firestore().collection("sensors").doc(sensorId).collection("ch")
            .where('t0', '>=', selectedDateQueryStart)
            .where('t0', '<', selectedDateQueryEnd)
            .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    setHideSpinnerCount( c => c + 1);
                    setTimeout(() => {
                        let timerId = helperCtx.createTimer();
                        helperCtx.currentTimerTime(timerId); // start timer
                        var data = change.doc.data();
                        var bucket = bucketRef.current;
                        for (var j = 0; j < data.ts.length; ++j) {
                            var ts = data.ts[j];
                            if (helperCtx.arrayContainsTs(bucket, ts) === false) {
                                var f1 = data.f1[j];
                                var f2 = data.f2[j];
                                var f3 = data.f3[j];
                                var f4 = data.f4[j];
                                var f5 = data.f5[j];
                                var f6 = data.f6[j];
                                var f7 = data.f7[j];
                                var f8 = data.f8[j];
                                var clr = data.clr[j];
                                var nir = data.nir[j];
                                var X = data.X[j];
                                var Y = data.Y[j];
                                var Z = data.Z[j];
                                var XX = X / (X + Y + Z);
                                var YY = Y / (X + Y + Z);
                                //var obj1 = helperCtx.get6Vars(ts,f1,f2,f3,f4,f5,f6,f7,f8,clr,nir,X,Y,Z);
                                var obj1 = helperCtx.get6VarsInit(ts);
                                var obj2 = { X: X, Y: Y, Z: Z, XX: XX, YY: YY, f1: f1, f2: f2, f3: f3, f4: f4, f5: f5, f6: f6, f7: f7, f8: f8, clr: clr, nir: nir }
                                var mergedObj = { ...obj1, ...obj2 };
                                bucket.push(mergedObj);
                            }
                        }
                        time1Ref.current = helperCtx.currentTimerTime(timerId); // start to 1
                        
                        bucketRef.current = bucket;
                        setStartAndEndDateTime(sensorId, "3");

                        var dateStart = dateStartRef.current;
                        var dateEnd = dateEndRef.current;
                        spdDataRef.current = [] ; // clear the list
                        // stuff everything into the list
                        var i;
                        for (i = 0; i < bucket.length; i++) {
                            if (bucket[i].ts >= dateStart && bucket[i].ts <= dateEnd) {
                                if (bucket[i].calculated === false) {
                                    var obj3 = helperCtx.get6Vars(
                                        bucket[i].ts,
                                        bucket[i].f1,
                                        bucket[i].f2,
                                        bucket[i].f3,
                                        bucket[i].f4,
                                        bucket[i].f5,
                                        bucket[i].f6,
                                        bucket[i].f7,
                                        bucket[i].f8,
                                        bucket[i].clr,
                                        bucket[i].nir,
                                        bucket[i].X,
                                        bucket[i].Y,
                                        bucket[i].Z
                                    );
                                    bucket[i] = {...bucket[i], ...obj3};
                                };
                            }
                        }

                        for (i = 0; i < bucket.length; i++) {
                            if (bucket[i].ts >= dateStart && bucket[i].ts <= dateEnd) {
                                helperCtx.insertInOrder (spdDataRef.current, {
                                    x: bucket[i].ts, 
                                    lux: bucket[i].lux, 
                                    cs: bucket[i].cs, 
                                    eml: bucket[i].eml, 
                                    cct: bucket[i].cct,
                                    spd: bucket[i].spd,
                                    XX: bucket[i].XX, 
                                    YY: bucket[i].YY
                                });
                            }
                        }
                        let st = helperCtx.currentTimerTime(timerId);
                        //setUpdateEpochNeeded(true);  // refresh the line
                        setHideSpinnerCount(c => c - 1);
                        let ed = helperCtx.currentTimerTime(timerId);
                        time2Ref.current = ed - st;
                        helperCtx.deleteTimer(timerId); // timer no longer in use
                    }, 10);
                });
            });
        }

        // return is called when getDateValue is called
        return (() => {
            if (unsubscribeFromDB !== null)
                unsubscribeFromDB();
            bucketRef.current = [];
            spdDataRef.current = [];
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTimeRange, sensorId]);
    // EFFECT STOP


    if (getTimeDivision === 1) {
        spdDataFilteredRef.current = Array.from(spdDataRef.current);
    } else {
        var tmp = [];
        var _ts = dateStartRef.current;
        var i = 0;
        var state = 0;
        if (spdDataRef.current.length > 0) {  // dont run the code if the spdDataRef is an empty array []
            while (_ts <= dateEndRef.current) {
                if (state === 0) {
                    // put the current i into the filter
                    tmp.push(spdDataRef.current[i++]);
                    state = 1;
                    _ts += (getTimeDivision * 60); // increase by the time division
                } else if(state === 1) {
                    if (i < spdDataRef.current.length) {
                        if (spdDataRef.current[i].x >= _ts) {
                            state = 0;
                        } else {
                            ++i;
                        }
                    } else {
                        break;
                    }
                }
            }
        }
        spdDataFilteredRef.current = tmp;
    }

    function getEpoch() {
        var index = helperCtx.getClosestIndexOfX(spdDataFilteredRef.current, ts);
        if (index !== -1) {
            var epoch = spdDataFilteredRef.current[index].x
            if (epoch !== undefined) {
                return epoch;
            }
        }
        return 0;
    }

    /*
    function selectedTime() {
        return getEpoch();
    }

    function getTs() {
        var epoch = selectedTime();
        if (epoch === 0)
            return "--/--/----, --:--:-- --"
        return helperCtx.getLocalDateText(epoch);
    }
    */

    function NotSelected() {
        return (
            <div>
                <div className="dpSensorName d-flex justify-content-center">
                    <div className="d-flex flex-row">
                        Select A Sensor                    
                    </div>
                    <div className="d-flex flex-row">
                        &nbsp;
                    </div>
                    <div className="d-flex flex-row">
                        {dropdown}
                    </div>
                </div>
            </div>
        );
    }

    function Selected() {
        return (
            <div>
                {
                // <div>DevicePage.js: {renderRef.current++} render counts</div>
                // <div>1. injest: {time1Ref.current}ms</div>
                // <div>2. render: {time2Ref.current}ms</div>
                }
                
                <div className="dpSensorName d-flex justify-content-center box1 pb-4">
                    <div className="d-flex flex-row">
                        {getSensorText(sensorId)}
                    </div>
                    <div className="d-flex flex-row">
                        &nbsp;
                    </div>
                    <div className="d-flex flex-row">
                        {dropdown}
                    </div>

                    <div style={{marginLeft: 10}}>
                        {(hideSpinnerCount > 0) && <Spinner size="sm" animation="border" />}
                    </div>
                </div>

                {getGraphView === 0 &&
                <div className="box2">
                    <DevicePageButtons
                        ts={getEpoch()} 
                        showGraph={getGraphView}
                        spdData={spdDataFilteredRef.current}
                        luxHidden={luxHiddenAttr} 
                        csHidden={csHiddenAttr} 
                        emlHidden={emlHiddenAttr} 
                        cctHidden={cctHiddenAttr}
                        luxOnClick={()=>{
                            if (getGraphView === 0) {
                                setLuxHiddenAttr(!luxHiddenAttr);
                            }
                        }} 
                        csOnClick={()=>{
                            if (getGraphView === 0) {
                                setCsHiddenAttr(!csHiddenAttr);
                            }
                        }} 
                        emlOnClick={()=>{
                            if (getGraphView === 0) {
                                setEmlHiddenAttr(!emlHiddenAttr);
                            }
                        }} 
                        cctOnClick={()=>{
                            if (getGraphView === 0) {
                                setCctHiddenAttr(!cctHiddenAttr);
                            }
                        }}
                        getBoxSize={box2whRef.current}
                        setBoxSize={(size) => {box2whRef.current = size;}}
                    />
                </div>
                }

                <div className="mt-1 graph speccon">
                    {getGraphView ===0 && 
                        <DeviceGraph 
                            ts={getEpoch()} 
                            spdData={spdDataFilteredRef.current}
                            luxHidden={luxHiddenAttr} 
                            csHidden={csHiddenAttr} 
                            emlHidden={emlHiddenAttr} 
                            cctHidden={cctHiddenAttr}
                            luxHiddenOnChange={setLuxHiddenAttr}
                            csHiddenOnChange={setCsHiddenAttr}
                            emlHiddenOnChange={setEmlHiddenAttr}
                            cctHiddenOnChange={setCctHiddenAttr}
                            cbChartArea={()=>{}}
                            useTimebar={false}
                            boxsize={box3}
                        /> 
                    }
                    {getGraphView === 1 && 
                        <DeviceSpectrum
                            ts={getEpoch()} 
                            spdData={spdDataFilteredRef.current}
                            cbChartArea={()=>{}}
                            useTimebar={false}
                            boxsize={box3}
                        /> 
                    }
                    {/*<div className="text">{getTs()}</div>*/}
                </div>
            </div>
        );
    };

    return (
        <div className="pagecontainer">
            { (sensorId !== "") ? <Selected/> : <NotSelected/> }
        </div>
    );
}
