import React, { useRef, useState, useContext } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenterEverything from "../components/CenterEverything"
import AdminContext from "../contexts/AdminContext"
import "./Profile.css"

export default function ChangeDisplayName() {
    const adminCtx = useContext(AdminContext);
    const displayNameRef = useRef();
    const { updateDisplayName } = useAuth();
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    async function handleSubmit(e) {
        const promises = [];

        e.preventDefault();
        setLoading(true);
        setErrorMsg("");

        if (displayNameRef.current.value !== adminCtx.displayName) {
            promises.push(updateDisplayName(displayNameRef.current.value));
        }

        Promise.all(promises).then(() => {
                adminCtx.setDisplayName(displayNameRef.current.value);
                history.push("/profile");
            }
        ).catch(() => {
                setErrorMsg("Failed to update account");
            }
        ).finally(() => {
                setLoading(false);
            }
        );
    }

    const disableButton = loading;

    return (
        <div className="profile">
            <CenterEverything>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Change Display Name</h2>
                        {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="displayName">
                                <Form.Label className="label">Display Name</Form.Label>
                                <Form.Control
                                type="input"
                                ref={displayNameRef}
                                required
                                defaultValue={adminCtx.displayName}
                                />
                            </Form.Group>
                            <Button disabled={disableButton} className="w-100 mt-3" type="submit">Change</Button>
                            <div className="w-100 text-center mt-2 bottom-text">
                                <Link to="/profile">Cancel</Link>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </CenterEverything>
        </div>
    );
}
