import "./DeviceMulti.css"
import DevicePage from "../components/DevicePage"
import React, { useState, useRef, useEffect } from "react"

    
import DeviceGraphRow2 from "../components/DeviceGraphRow2"
//import DeviceGraphRow2NoDate from "../components/DeviceGraphRow2NoDate"
//import DeviceGraphSliderWithoutButtons2 from "../components/DeviceGraphSliderWithoutButtons2"
import { useHelper } from "../contexts/HelperContext"
import firebase from "../firebase"
import { useAuth } from "../contexts/AuthContext"
import { Dropdown, DropdownButton, ButtonGroup, Button } from "react-bootstrap"

//http://localhost:3001/devicemulti?dev0=pupil_245DFC210001&dev1=pupil_245DFC21000A&dev2=pupil_245DFC210019&dev3=pupil_245DFC21001C
export default function DeviceMulti() {
    // console.log("DeviceMulti() render");
    const [getGraphView, setGraphView] = useState(1); // 0 is graph, 1 is spectrum
    const [getDateValue, setDateValue] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const [getTimeDivision, setTimeDivision] = useState(1);
    const [getTimeRange, setTimeRange] = useState(1);
    const [getSensor1id, setSensor1id] = useState("");
    const [getSensor2id, setSensor2id] = useState("");
    const [getSensor3id, setSensor3id] = useState("");
    const [getSensor4id, setSensor4id] = useState("");
    const [getTs, setTs] = useState(0);
    const [getSensors, setSensors] = useState([]);

    const dateValueRef = useRef(getDateValue); // shadow variables that are always current
    const timeRangeRef = useRef(getTimeRange);
    const dateStartRef = useRef(0);
    const dateEndRef = useRef(0);
    const forceOnChangeRef = useRef("");
    const { currentUser } = useAuth();
    const helperCtx = useHelper();
    const localButtonHeightRef = useRef(0);
    
    //
    //const updateToEndRef = useRef(true);
    //const deviceGraphSliderWithoutButtons2WindowSizeRef = useRef({});
    //const [getHideSpinnerCount, setHideSpinnerCount] = useState(0);
    const setIndexToEndRef=useRef(false);


    function UpdateURL(id0, id1, id2, id3) {        
        var url = window.location.href; 
        // remove passing variables from url
        var newurl = url.substring(0, url.indexOf("?"));
        var newurlrev = newurl.split("").reverse().join("");
        newurlrev = newurlrev.substring(0, newurlrev.indexOf("/")+1);
        newurl = newurlrev.split("").reverse().join("");
        var count = 0;
        if (id0 !== "" && id0 !== undefined)
            newurl += (((count++ === 0) ? '?':'&') + 'dev0=' + id0);
        if (id1 !== "" && id1 !== undefined)
            newurl += (((count++ === 0) ? '?':'&') + 'dev1=' + id1);
        if (id2 !== "" && id2 !== undefined)
            newurl += (((count++ === 0) ? '?':'&') + 'dev2=' + id2);
        if (id3 !== "" && id3 !== undefined)
            newurl += (((count++ === 0) ? '?':'&') + 'dev3=' + id3);
        // console.log(newurl);
        window.history.replaceState(null, "", newurl)
    }

    useEffect(() => {
        // effect, decode the URL
        var url = window.location.href; 
        var newParams = ["","","",""];
        if (url.indexOf("?") >= 0) {
            var params_str = url.substring(url.indexOf("?")+1);
            var params = [];
            // break into each parameter
            while (params_str.indexOf("&") >= 0) {
                var str = params_str.substring(0, params_str.indexOf("&"));
                params.push(str);
                params_str = params_str.substring(params_str.indexOf("&") + 1)
            }
            if (params_str.length > 0) {
                params.push(params_str);
            }
            // strip the attribue and the value
            params.forEach((param) => {
                var attr = param.substring(0, param.indexOf("="));
                var value = param.substring(param.indexOf("=") + 1);
                if (attr === 'dev0') {
                    setSensor1id(value);
                    newParams[0]=value;
                }
                if (attr === 'dev1') {
                    setSensor2id(value);
                    newParams[1]=value;
                }
                if (attr === 'dev2') {
                    setSensor3id(value);
                    newParams[2]=value;
                }
                if (attr === 'dev3') {
                    setSensor4id(value);
                    newParams[3]=value;
                }
            })
            UpdateURL(newParams[0], newParams[1], newParams[2], newParams[3]);
        }
        
        // effect end
        return (() => {
        });
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const [getWindowSize, setWindowSize] = useState({w: window.innerWidth, h: window.innerHeight});
    useEffect(() => {
        function handleResize() {
            setWindowSize({w: window.innerWidth, h: window.innerHeight});
        }
        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    });

    useEffect(() => {
        // console.log("DeviceMulti() useEffect() start");
        // READ ONE TIME ONLY
        firebase.firestore().collection("sensors")
        .where("followers", "array-contains", currentUser.uid).get().then((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var id = change.doc.id;
                var data = change.doc.data();      
                var devices = getSensors; 
                var index = helperCtx.getIndexOfId(devices, id);
                if (change.type === 'added' || change.type === 'modified') {
                    if (index >= 0) {
                        devices[index].data = data;
                    } else {
                        devices.push ({'id': id, 'data': data});
                    }
                } else if (change.type === 'removed') {
                    devices.splice(index, 1);
                }
                // console.log("1", devices);
                setSensors([...devices]);
                // console.log("2", devices);
            });
        });

        return (() => {
            // console.log("DeviceMulti() useEffect() end");
            setSensors([]);
        });
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setStartAndEndDateTime(id, from) {
        // console.log("setStartAndEndDateTime(from)", from);
        var dateValue = dateValueRef.current;

        var selectedDateStart = Date.parse(dateValue) / 1000;
        var selectedDateEnd = selectedDateStart;

        selectedDateEnd = (Date.parse(new Date()) / 1000);
        selectedDateStart = selectedDateEnd - (60 * 60 * timeRangeRef.current);
        setTs(selectedDateEnd);

        dateStartRef.current = selectedDateStart;
        dateEndRef.current = selectedDateEnd;
        
        forceOnChangeRef.current = id;

        console.log(
            "id", id,
            "from", from,
            "selectedDateStart", 
            helperCtx.getLocalDateTextMilitary(selectedDateStart), 
            "selectedDateEnd", 
            helperCtx.getLocalDateTextMilitary(selectedDateEnd)
        );
    }

    const DeviceDropDown = ({sensors, index}) => {
        var ret = [];
        function deviceClicked(sensor, index) {
            // if sensor is undefined, then store the value as ""
            // else get the sensor id and store that!
            var sensorid = "";
            if (sensor !== undefined) {
                if (sensor.hasOwnProperty('id')) {
                    sensorid = sensor.id;
                }
            }
            switch (index) {
                case 0:
                    setSensor1id(sensorid);
                    UpdateURL(sensorid, getSensor2id, getSensor3id, getSensor4id);
                    break;
                case 1:
                    setSensor2id(sensorid);
                    UpdateURL(getSensor1id, sensorid, getSensor3id, getSensor4id);
                    break;
                case 2:
                    setSensor3id(sensorid);
                    UpdateURL(getSensor1id, getSensor2id, sensorid, getSensor4id);
                    break;
                case 3:
                    setSensor4id(sensorid);
                    UpdateURL(getSensor1id, getSensor2id, getSensor3id, sensorid);
                    break;
                default:
                    break;
            }
        }

        function DeviceMetaName(sensor) {
            if (sensor === "") {
                return "";
            }

            var sensorMetaName = "";
            getSensors.forEach((metasensor) => {
                if (sensor.id === metasensor.id) {
                    if (sensor.data.hasOwnProperty('meta')) {
                        if (sensor.data.meta.hasOwnProperty('name')) {
                            sensorMetaName = sensor.data.meta.name;
                        }
                    }
                }
            });
            if (sensorMetaName === "") {
                return helperCtx.translateId(sensor.id);
            }
            return sensorMetaName + " (" + helperCtx.translateId(sensor.id) + ")";
        }

        ret.push(<Dropdown.Item 
            key={0} 
            onClick={()=>{deviceClicked(undefined, index)}}
            >
            {DeviceMetaName("")}
            </Dropdown.Item>);

        sensors.forEach((sensor) => {
            ret.push(<Dropdown.Item 
                key={sensor.id} 
                onClick={()=>{deviceClicked(sensor, index)}}
                >
                {DeviceMetaName(sensor)}
                </Dropdown.Item>);
        });

        return (
            <DropdownButton size="sm" id="dropdown-basic" title="">
                {ret}
            </DropdownButton>
        );
    }

    function boxstyle() {
        let w = getWindowSize.w;
        let h = getWindowSize.h;// - helperCtx.getBannerHeight() - helperCtx.getFooterHeight() - 30; 
        
        var box = document.querySelector('.buttonbox');
        if (box !== null) {
            h -= box.offsetHeight;
            localButtonHeightRef.current = box.offsetHeight;
        }
        return {width: parseInt(w * .40), height: parseInt(h *.40)};
    }

    return (
        // Banner Height is fixed at 109 high
        <div>
            {
            <div className="buttonbox buttonformat d-flex flex-row">
                <ButtonGroup className="p-3 mt-2">
                    <Button variant={getGraphView === 1 ? "primary" : "secondary"} onClick={() => {setGraphView(1)}}>Spectrum</Button>
                    <Button variant={getGraphView === 0 ? "primary" : "secondary"} onClick={() => {setGraphView(0)}}>Graph</Button>
                </ButtonGroup>
                <DeviceGraphRow2 
                    sensor={"test sensor"}
                    getDateValue={getDateValue}
                    setDateValue={setDateValue} 
                    getHideSpinnerCount={0}
                    getTimeDivision={getTimeDivision}
                    setTimeDivision={setTimeDivision}
                    setTimeRange={setTimeRange}
                    setStartAndEndDateTime={setStartAndEndDateTime}
                    getTimeRangeRef={timeRangeRef.current}
                    setTimeRangeRef={(value)=> {timeRangeRef.current = value}}
                    timeRangeIsReduced={false}
                    setDateValueRef={(value) =>{dateValueRef.current = value}}
                    setQueueSetIndexToEndRef={(value) => {setIndexToEndRef.current = value;}}
                ></DeviceGraphRow2>
            </div>
            }

            {
            /*
            <div className="orange">DeviceMulti.js: {renderRef.current++} render counts</div>
            <div>
                <DeviceGraphRow2NoDate 
                getGraphView={getGraphView}
                setGraphView={setGraphView}
                getTimeDivision={getTimeDivision}
                setTimeDivision={setTimeDivision}
                setTimeRange={setTimeRange}
                getHideSpinnerCount={getHideSpinnerCount}
                getTimeRangeRef={timeRangeRef.current}
                setTimeRangeRef={(value)=> {timeRangeRef.current = value}}
                setStartAndEndDateTime={setStartAndEndDateTime}
                id="none"
                setWindowSize={(w, h) => {
                    deviceGraphRow2NoDateWindowSizeRef.current = {w: w, h: h};
                }}
                />
            </div>
            */
            }
            {
            /*
            <div>
                <DeviceGraphSliderWithoutButtons2
                    dateStart={dateStartRef.current}
                    dateEnd={dateEndRef.current}
                    timeDivision={getTimeDivision}
                    ForceOnChange={forceOnChangeRef.current}
                    OnChange={(value, id)=>{
                        forceOnChangeRef.current = "";
                        setTs(value);
                        if (id !== getSensor1id)
                            setRedraw1(getRedraw1+1);
                        if (id !== getSensor2id)
                            setRedraw2(getRedraw2+1);
                        if (id !== getSensor3id)
                            setRedraw3(getRedraw3+1);
                        if (id !== getSensor4id)
                            setRedraw4(getRedraw4+1);                
                    }}
                    getUpdateToEnd={updateToEndRef.current}
                    setUpdateToEnd={(value)=>{updateToEndRef.current = value;}}
                    setWindowSize={(w, h) => {
                        deviceGraphSliderWithoutButtons2WindowSizeRef.current = {w: w, h: h};
                    }}
                    />
            </div>
            */
            }

            <div className="boxcontainer">
                <div className="box" style={boxstyle()}>
                    <DevicePage 
                        boxsize={boxstyle()}
                        sensors={getSensors}
                        sensorId={getSensor1id}
                        dropdown={<DeviceDropDown sensors={getSensors} index={0}/>}
                        getGraphView={getGraphView}
                        getTimeDivision={getTimeDivision}
                        getTimeRange={getTimeRange}
                        dateValueRef = {dateValueRef}
                        timeRangeRef = {timeRangeRef}
                        setStartAndEndDateTime={setStartAndEndDateTime}
                        dateStartRef = {dateStartRef}
                        dateEndRef = {dateEndRef}
                        ts={getTs}
                        />
                </div>

                <div className="box" style={boxstyle()}>
                    <DevicePage 
                        boxsize={boxstyle()}
                        sensors={getSensors}
                        sensorId={getSensor2id}
                        dropdown={<DeviceDropDown sensors={getSensors} index={1}/>}
                        getGraphView={getGraphView}
                        getTimeDivision={getTimeDivision}
                        getTimeRange={getTimeRange}
                        setStartAndEndDateTime={setStartAndEndDateTime}
                        dateValueRef = {dateValueRef}
                        timeRangeRef = {timeRangeRef}
                        dateStartRef = {dateStartRef}
                        dateEndRef = {dateEndRef}
                        ts={getTs}
                        />
                </div>
            </div>
            <br className="containerdivNewLine" />
            <div className="boxcontainer">
                <div className="box" style={boxstyle()}>
                    <DevicePage 
                        boxsize={boxstyle()}
                        sensors={getSensors}
                        sensorId={getSensor3id}
                        dropdown={<DeviceDropDown sensors={getSensors} index={2}/>}
                        getGraphView={getGraphView}
                        getTimeDivision={getTimeDivision}
                        getTimeRange={getTimeRange}
                        setStartAndEndDateTime={setStartAndEndDateTime}
                        dateValueRef = {dateValueRef}
                        timeRangeRef = {timeRangeRef}
                        dateStartRef = {dateStartRef}
                        dateEndRef = {dateEndRef}
                        ts={getTs}
                        />
                </div>

                <div className="box" style={boxstyle()}>
                    <DevicePage
                        boxsize={boxstyle()}
                        sensors={getSensors}
                        sensorId={getSensor4id}
                        dropdown={<DeviceDropDown sensors={getSensors} index={3}/>}
                        getGraphView={getGraphView}
                        getTimeDivision={getTimeDivision}
                        getTimeRange={getTimeRange}
                        setStartAndEndDateTime={setStartAndEndDateTime}
                        dateValueRef = {dateValueRef}
                        timeRangeRef = {timeRangeRef}
                        dateStartRef = {dateStartRef}
                        dateEndRef = {dateEndRef}
                        ts={getTs}
                        />
                </div>
            </div>
        </div>
    );
}

