import React, {createContext, useState, useEffect, useContext } from "react"
import firebase, { auth } from "../firebase"

const AdminContext = createContext({
    isAdminAccount: false,
    displayName: "initContext",
    setDisplayNameHandler: (newDisplayName) => {
    }
});

export function useAdmin() {
    return useContext(AdminContext);
}

export function AdminContextProvider({ children }) {
    const [isAdminAccount, setIsAdminAccount] = useState(false);
    const [displayName, setDisplayName] = useState();
    
    useEffect(() => {
        var unsubscribe1 = null;
        var unsubscribe2 = null;

        setIsAdminAccount(false);
        setDisplayName(auth.displayName);

        unsubscribe1 = auth.onAuthStateChanged((user) => {

            if (user == null)
            {
                setDisplayName("");
                if (unsubscribe2 != null) {
                    unsubscribe2();
                    unsubscribe2 = null;
                }
                setIsAdminAccount(false);
            } else {
                setDisplayName(user.displayName);

                unsubscribe2 = firebase.firestore().collection("keys")
                .where("admin", "==", true)
                .onSnapshot((querySnapshot) => {
                    querySnapshot.docChanges().forEach((change) => {
                        if (change.type === 'added' || change.type === 'modified') {
                            setIsAdminAccount(true);
                        } else if (change.type === 'removed') {
                            setIsAdminAccount(false);
                        }
                    });
                });        
            }
        });

        return (() => {
            if (unsubscribe2 != null) {
                unsubscribe2();
                unsubscribe2 = null;
            }
            if (unsubscribe1 != null) {
                unsubscribe1();
                unsubscribe1 = null;
            }
        });
    }, []);

    function setDisplayNameHandler(newDisplayName) {
        setDisplayName(newDisplayName);
    }

    const context = {
        isAdminAccount: isAdminAccount,
        displayName: displayName,
        setDisplayName: setDisplayNameHandler
    };

    return (
        <AdminContext.Provider value={context}>
        {children}
        </AdminContext.Provider>
    );
}

export default AdminContext;