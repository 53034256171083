import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
require('dotenv').config();

var firebaseConfig = {
    apiKey: "AIzaSyACSUs2c1xQzY-lV9EiGbAUDVNEuESqnd4",
    authDomain: "blue-iris-labs-289016.firebaseapp.com",
    databaseURL: "https://blue-iris-labs-289016.firebaseio.com",
    projectId: "blue-iris-labs-289016",
    storageBucket: "blue-iris-labs-289016.appspot.com",
    messagingSenderId: "739103725263",
    appId: "1:739103725263:web:bc71fd244b8897e19009ba",
    measurementId: "G-VEZFJBT93Q"
}

var firebaseConfigDev = {
    apiKey: "AIzaSyATGnUHRoTPgBHiQhK2UYBXw6vBkWHDTvE",
    authDomain: "test-blue-iris-labs-326117.firebaseapp.com",
    projectId: "test-blue-iris-labs-326117",
    storageBucket: "test-blue-iris-labs-326117.appspot.com",
    messagingSenderId: "371322134772",
    appId: "1:371322134772:web:8d0e09ebd50f5a3377fea1",
    measurementId: "G-2CDCEFTZWD"  
}


export const app = firebase.initializeApp(process.env.REACT_APP_DEV_ENV === 'true' ? firebaseConfigDev : firebaseConfig);
export const auth = app.auth();
export default firebase;
