import React, {useState, useEffect, useRef } from "react"
import DeviceSpectrum from "../components/DeviceSpectrum"
import DeviceGraph from "../components/DeviceGraph"
import DeviceGraphSliderWithButtons from "../components/DeviceGraphSliderWithButtons"
import firebase from "../firebase"
import { useHelper } from "../contexts/HelperContext"
import DownloadModal from "../components/DeviceDownloadData"
import MetaModal from "../components/DeviceMetaInput"
import DeviceGraphRow1 from "../components/DeviceGraphRow1"
import DeviceGraphRow2 from "../components/DeviceGraphRow2"
import { Button } from "react-bootstrap"
import {DatePicker} from 'antd'
import dayjs from 'dayjs'

import "./Device.css"

// The Device page is a shell that calls the DeviceSpectrum or DeviceGraph page

// [getGraphView, setGraphView] selects spectrum or graph
// bucket1 holds the spectrum data

export default function Device() {
    const [getGraphView, setGraphView] = useState(0);   // graph
    //const [getGraphView, setGraphView] = useState(1); // spectrum
    //const [getGraphView, setGraphView] = useState(2); // xy
    const [getTimeDivision, setTimeDivision] = useState(1); // Button
    const [getHideSpinnerCount, setHideSpinnerCount] = useState(0);
    const [getCsHidden, setCsHidden] = useState(false); // Button
    const [getLuxHidden, setLuxHidden] = useState(true); // Button
    const [getMediHidden, setMediHidden] = useState(true); // Button
    const [getCctHidden, setCctHidden] = useState(true); // Button
    const [getDateValue, setDateValue] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const [getTimeRange, setTimeRange] = useState(1); // Button
    const [getChartArea, setChartArea] = useState({top: 0, left: 25, height: 0, width: 0, right: 0, bottom: 0});
    const [getRedrawPage, setRedrawPage] = useState(false);
    const [getShowDownloadModal, setShowDownloadModal] = useState(false); // Download button
    const [getShowMetaModal, setShowMetaModal] = useState(false);
    //setRedrawPage(c => c + 1);

    const updateIndexNeededRef = useRef(false);
    const updateEpochNeededRef = useRef(false);
    const tsRef = useRef(0);
    const deviceIdRef = useRef("");
    const indexValueRef = useRef(0);
    const dateValueRef = useRef(getDateValue); // shadow variables that are always current
    const timeRangeRef = useRef(getTimeRange);
    const bucketRef = useRef([]);
    const bucket2Ref = useRef([]);
    const dateStartRef = useRef(0);
    const dateEndRef = useRef(0);
    const downloadStartRef = useRef(0);
    const downloadEndRef = useRef(0);
    const spdDataRef = useRef([]);
    const spdDataFilteredRef = useRef([]);
    const setIndexToEndRef = useRef(true);
    const timeRangeIsReducedRef = useRef(false);  

    const helperCtx = useHelper();
    const metaDataRef = useRef({});

    // EFFECT START
    useEffect(() => {
        // Decode the selected device id to subscribe to
        var url = window.location.href; 
        var param = url.substring(url.indexOf("?")+1);
        var selectedDeviceId = param.substring(param.indexOf("=")+1);
        deviceIdRef.current = selectedDeviceId;

        // get the meta data of the device.
        metaDataRef.current = {};
        helperCtx.initalMetaArray().forEach((item) => {
            metaDataRef.current[item.metaname] = "";
        })
        var unsubDevice = firebase.firestore().collection("sensors").doc(selectedDeviceId)
        .onSnapshot((doc) => {
            if (doc.exists) {
                var jdata = doc.data();
                if (jdata.hasOwnProperty("meta") === true) {
                    Object.keys(jdata["meta"]).forEach((key) => {
                        if (jdata["meta"].hasOwnProperty(key)) {
                            metaDataRef.current[key] = jdata["meta"][key];
                        }
                    })
                }
                setRedrawPage(c => c + 1);
            }
        });

        // Decode the date selected
        var selectedDateStart = Date.parse(dateValueRef.current) / 1000;

        var selectedDateQueryStart;
        var selectedDateQueryEnd;

        selectedDateQueryEnd = selectedDateStart + (60 * 60 * 24);
        selectedDateQueryStart = selectedDateStart - (60 * 60 * 24) - (60 * 60 * timeRangeRef.current) ;

        setStartAndEndDateTime("4");

        // Subscribe to the database for the selected time

        // unscubscribeFromDB is the compliment function to the document subscribe statement.
        // The useEffect() statement runs the body of the function and waits for getDateValue to 
        // change before redrawing.
        var unsubscribeFromDB1 = firebase.firestore().collection("sensors").doc(selectedDeviceId).collection("ch")
        .where('t0', '>=', selectedDateQueryStart)
        .where('t0', '<', selectedDateQueryEnd)
        .onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                setHideSpinnerCount(c => c + 1);
                setTimeout(() => {
                    setStartAndEndDateTime("3");

                    var data = change.doc.data();
                    var bucket = bucketRef.current;
                    for (var j = 0; j < data.ts.length; ++j) {
                        var ts = data.ts[j];
                        // only add things to the Bucket that are being shown
                        if (ts > dateStartRef.current && ts <= dateEndRef.current) 
                        {
                            if (helperCtx.arrayContainsTs(bucket, ts) === false) {
                                var f1 = data.f1[j];
                                var f2 = data.f2[j];
                                var f3 = data.f3[j];
                                var f4 = data.f4[j];
                                var f5 = data.f5[j];
                                var f6 = data.f6[j];
                                var f7 = data.f7[j];
                                var f8 = data.f8[j];
                                var clr = data.clr[j];
                                var nir = data.nir[j];
                                var X = data.X[j];
                                var Y = data.Y[j];
                                var Z = data.Z[j];
                                var XX = X / (X + Y + Z);
                                var YY = Y / (X + Y + Z);
                                var obj1 = helperCtx.get6Vars(ts,f1,f2,f3,f4,f5,f6,f7,f8,clr,nir,X,Y,Z);
                                var obj2 = { XX: XX, YY: YY, 
                                    f1: f1, f2: f2, f3: f3, f4: f4, f5: f5, f6: f6, f7: f7, f8: f8, 
                                    clr: clr, nir: nir,
                                    X: X, Y: Y, Z: Z, lt: helperCtx.getLocalTimeText(ts), 
                                    id: change.doc.id, sensor: deviceIdRef.current
                                }
                                var mergedObj = { ...obj1, ...obj2 };
                                bucket.push(mergedObj);
                            }
                        }
                    }
                    bucketRef.current = bucket;

                    if (indexValueRef.current === (spdDataFilteredRef.current.length - 1)) {
                        setIndexToEndRef.current = true;
                    } else {
                        if (indexValueRef.current > 0) {
                            if (getTimeDivision === 1) {
                                // This code is only valid if the time division is low.
                                indexValueRef.current = indexValueRef.current - 1;
                            }
                        }
                    }
                    updateEpochNeededRef.current = true;  // refresh the line
                    setHideSpinnerCount(c => c - 1);
                }, 10);
            });
        });

        var unsubscribeFromDB2 = firebase.firestore().collection("sensors").doc(selectedDeviceId).collection("cache")
        .where('t0', '>=', selectedDateQueryStart)
        .where('t0', '<', selectedDateQueryEnd)
        .onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                setHideSpinnerCount(c => c + 1);
                setTimeout(() => {
                    setStartAndEndDateTime("3");

                    var data = change.doc.data();
                    var bucket2 = bucket2Ref.current;
                    for (var j = 0; j < data.ts.length; ++j) {
                        var ts = data.ts[j];
                        // only add things to the bucket2 that are being shown
                        if (ts > dateStartRef.current && ts <= dateEndRef.current) 
                        {
                            if (helperCtx.arrayContainsTs(bucket2, ts) === false) {
                                // todo: when adding eml, older cache won't have it, so
                                // check .hasOwnProperty("eml") and merge it in if it exists

                                var obj2 = { 
                                    cct: data.cct[j], 
                                    cla: data.cla[j], 
                                    cs: data.cs[j], 
                                    duv: data.duv[j], 
                                    slux: data.slux[j], 
                                    ts: data.ts[j], 
                                    x: data.ts[j], 
                                }
                                bucket2.push(obj2);
                            }
                        }
                    }
                    bucket2Ref.current = bucket2;

                    if (indexValueRef.current === (spdDataFilteredRef.current.length - 1)) {
                        setIndexToEndRef.current = true;
                    } else {
                        if (indexValueRef.current > 0) {
                            if (getTimeDivision === 1) {
                                // This code is only valid if the time division is low.
                                indexValueRef.current = indexValueRef.current - 1;
                            }
                        }
                    }
                    updateEpochNeededRef.current = true;  // refresh the line
                    setHideSpinnerCount(c => c - 1);
                }, 10);
            });
        });

        // return is called when getDateValue is called
        return (() => {
            unsubscribeFromDB1();
            unsubscribeFromDB2();
            unsubDevice();
            bucketRef.current = [];
            spdDataRef.current = [];
            metaDataRef.current = {};
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [getDateValue, getTimeRange]);
    // EFFECT STOP

    function setStartAndEndDateTime(from) {
        var dateValue = dateValueRef.current;

        var selectedDateStart = Date.parse(dateValue) / 1000;
        var selectedDateEnd;
        var todayDateStart = Date.parse(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) / 1000;

        if (selectedDateStart === todayDateStart) {
            selectedDateEnd = (Date.parse(new Date()) / 1000);
            selectedDateStart = selectedDateEnd - (60 * 60 * timeRangeRef.current);
            timeRangeIsReducedRef.current=(false);
        } else {
            selectedDateEnd = selectedDateStart + (60 * 60 * 24);
            selectedDateStart = selectedDateEnd - (60 * 60 * timeRangeRef.current);
            timeRangeIsReducedRef.current=(true);
        }
        dateStartRef.current = selectedDateStart;
        dateEndRef.current = selectedDateEnd;

        //console.log(
        //    from,
        //    "selectedDateStart", 
        //    helperCtx.getLocalDateTextMilitary(selectedDateStart), 
        //    "selectedDateEnd", 
        //    helperCtx.getLocalDateTextMilitary(selectedDateEnd)
        //);

    }

    function onDownloadCalendarChange(dates) {
        var dStartDate = dates[0].hour(0).minute(0).second(0)
        var dEndDate = dates[1].hour(23).minute(59).second(59)

        downloadStartRef.current = dayjs(dStartDate).unix()
        downloadEndRef.current = dayjs(dEndDate).unix()
    }

    var dateStart = dateStartRef.current;
    var dateEnd = dateEndRef.current;
    spdDataRef.current = [] ; // clear spdDataRef
    var bucket = bucketRef.current;
    // stuff everything into spdDataRef
    var i;
    var _ts;
    for (i = 0; i < bucket.length; i++) {
        if (bucket[i].ts >= dateStart && bucket[i].ts <= dateEnd) {
            if (helperCtx.xDataContains(spdDataRef.current, bucket[i].ts) === false) {
                helperCtx.insertInOrder (spdDataRef.current, {
                    x: bucket[i].ts, 
                    lux: bucket[i].lux, 
                    cs: bucket[i].cs, 
                    eml: bucket[i].eml, 
                    cct: bucket[i].cct,
                    spd: bucket[i].spd,
                    XX: bucket[i].XX, 
                    YY: bucket[i].YY
                });
            }
        }
    }
    
    // fill in the gaps of spdDataRef (mostly not used now that we store 0s)
    _ts = dateStart;
    i = 0;                    
    while (_ts <= dateEnd) {
        if (i < spdDataRef.current.length) {
            if (spdDataRef.current[i].x <= _ts) {
                i = i + 1;
            } else if (spdDataRef.current[i].x <= (_ts + 600)) {
                _ts = spdDataRef.current[i].x;
            } else {
                helperCtx.insertInOrder (spdDataRef.current, {
                    x: _ts,
                    lux: 0,
                    cs: 0,
                    eml: 0,
                    cct: 0,
                    spd: helperCtx.zeroSpd(),
                });
                _ts = _ts + 60;
            }
        }
        else {
            _ts = _ts + 60;
        }
    }

    
    if (getTimeDivision === 1) {
        spdDataFilteredRef.current = Array.from(spdDataRef.current);
    } else {
        var tmp = [];
        _ts = dateStartRef.current;
        i = 0;
        var state = 0;
        if (spdDataRef.current.length > 0) {  // dont run the code if the spdDataRef is an empty array []
            while (_ts <= dateEndRef.current) {
                if (state === 0) {
                    // put the current i into the filter
                    tmp.push(spdDataRef.current[i++]);
                    state = 1;
                    _ts += (getTimeDivision * 60); // increase by the time division
                } else if(state === 1) {
                    if (i < spdDataRef.current.length) {
                        if (spdDataRef.current[i].x >= _ts) {
                            state = 0;
                        } else {
                            ++i;
                        }
                    } else {
                        break;
                    }
                }
            }
        }
        spdDataFilteredRef.current = tmp;
    }

    if (setIndexToEndRef.current === true) {
        if (spdDataFilteredRef.current.length > 0) {
            setIndexToEndRef.current = false;
            indexValueRef.current = spdDataFilteredRef.current.length - 1;
            updateEpochNeededRef.current = true;
        }
    }

    if (updateEpochNeededRef.current === true) {
        updateEpochNeededRef.current = false;
        var value = indexValueRef.current;
        if (value >= 0) {
            if (spdDataFilteredRef.current !== undefined) {
                if (value < spdDataFilteredRef.current.length) {
                    var vars = spdDataFilteredRef.current[value];
                    if (vars !== undefined) {
                        var epoch = spdDataFilteredRef.current[value].x;
                        if (epoch !== undefined) {
                            tsRef.current = epoch;
                        }
                    }
                }    
            }
        }
    }

    var array = spdDataFilteredRef.current;
    if (updateIndexNeededRef.current === true) {
        updateIndexNeededRef.current = false;
        for (i = 0; i < array.length; i++) {
            if (array[i].x >= tsRef.current) {
                indexValueRef.current = i;
                tsRef.current = array[i].x;
                break;
            }
        }
    }

    for (i = 0; i < array.length; i++) {
        if (array[i] === undefined) {
            console.log ("array[i] is undefined", "i", i, "array", array);
        }
        else {
            var k = helperCtx.getIndexOfX(bucket2Ref.current, array[i].x, "Device.js line 377");
            if (k >= 0) {
                if (bucket2Ref.current[k].hasOwnProperty("cs"))
                    array[i].cs = bucket2Ref.current[k].cs; // override CS calculations with cache value
                if (bucket2Ref.current[k].hasOwnProperty("slux"))
                    array[i].lux = bucket2Ref.current[k].slux;
                if (bucket2Ref.current[k].hasOwnProperty("cct"))
                    array[i].cct = bucket2Ref.current[k].cct;
            }
        }
    }


    function getDeviceDisplayName() {
        var metaname = "";
        if (metaDataRef.current.hasOwnProperty("name")) {
            metaname=metaDataRef.current["name"];
        }
        if (metaname !== "") {
            return metaname;    
        }
        return deviceIdRef.current;
    }

    // use bucket2 if possible, so that we are using the cache values!

    const [getWindowSize, setWindowSize] = useState({w: window.innerWidth, h: window.innerHeight});
    useEffect(() => {
        function handleResize() {
            setWindowSize({w: window.innerWidth, h: window.innerHeight});
        }
        window.addEventListener('resize', handleResize);
        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    });

    // calculate the size of the page
    var box1 = document.querySelector('.box1');
    var box1Height = 0;
    if (box1 !== null) {
        box1Height = box1.offsetHeight;
    }    

    var box2 = document.querySelector('.box2');
    var box2Height = 0;
    if (box2 !== null) {
        box2Height = box2.offsetHeight;
    }    

    var box3 = document.querySelector('.box3');
    var box3Size = {width: 0, height: 0};
    if (box3 !== null) {
        box3Size.width = box3.offsetWidth;
        box3Size.height = box3.offsetHeight;
    }

    var box4 = document.querySelector('.box4');
    var box4Size = {width: 0, height: 0};
    if (box4 !== null) {
        box4Size.width = box4.offsetWidth;
        box4Size.height = getWindowSize.h - box1Height - box2Height - box3Size.height; // - 30 - helperCtx.getFooterHeight() - helperCtx.getBannerHeight());
    }



    return (
        <div className="container">
            <div className="box1">
                <DeviceGraphRow1 
                    deviceId={getDeviceDisplayName()} 
                    rangePickerChanged={onDownloadCalendarChange}
                    cbDownloadDataPressed={()=>{setShowDownloadModal(true);}}
                    getGraphView={getGraphView}
                    setGraphView={setGraphView}
                    getHideSpinnerCount={getHideSpinnerCount}
                />
            </div>
            <div className="box2 d-flex flex-row">
                <div>
                    <DeviceGraphRow2 
                        getDateValue={getDateValue}
                        setDateValue={setDateValue}
                        getTimeDivision={getTimeDivision}
                        setTimeDivision={(td) => {
                            updateIndexNeededRef.current = true;
                            setTimeDivision(td);
                        }}
                        setTimeRange={setTimeRange}
                        getHideSpinnerCount={getHideSpinnerCount}
                        getTimeRangeRef={timeRangeRef.current}
                        setTimeRangeRef={(value) => {timeRangeRef.current = value}}
                        timeRangeIsReduced={timeRangeIsReducedRef.current}
                        setDateValueRef={(value) =>{dateValueRef.current = value}}
                        setQueueSetIndexToEndRef= {(value) => {setIndexToEndRef.current = value;}}    
                        setStartAndEndDateTime={setStartAndEndDateTime}
                    />
                </div>
                <div className="ms-auto" onClick={() => {setShowMetaModal(true)}}>
                    <Button className="meta">Sensor Metadata</Button>
                </div>
            </div>
            <div className="box3 d-flex">
                <DeviceGraphSliderWithButtons 
                    value={indexValueRef.current}
                    onChange={(value) => {
                        indexValueRef.current = value;
                        updateEpochNeededRef.current = true;
                        setRedrawPage(getRedrawPage + 1);
                    }}
                    showGraph={getGraphView}
                    spdData={spdDataFilteredRef.current}
                    luxHidden={getLuxHidden} 
                    csHidden={getCsHidden} 
                    mediHidden={getMediHidden} 
                    cctHidden={getCctHidden}
                    luxOnClick={()=>{
                        if (getGraphView === 0) {
                            setLuxHidden(!getLuxHidden);
                        }
                    }} 
                    csOnClick={()=>{
                        if (getGraphView === 0) {
                            setCsHidden(!getCsHidden);
                        }
                    }} 
                    mediOnClick={()=>{
                        if (getGraphView === 0) {
                            setMediHidden(!getMediHidden);
                        }
                    }} 
                    cctOnClick={()=>{
                        if (getGraphView === 0) {
                            setCctHidden(!getCctHidden);
                        }
                    }}
                    chartArea={getChartArea}
                />
            </div>


            
            <div className="graphElement box4">
                {getGraphView ===0 && 
                    <DeviceGraph 
                        ts={tsRef.current} 
                        spdData={spdDataFilteredRef.current}
                        luxHidden={getLuxHidden} 
                        csHidden={getCsHidden} 
                        mediHidden={getMediHidden} 
                        cctHidden={getCctHidden}
                        luxHiddenOnChange={setLuxHidden}
                        csHiddenOnChange={setCsHidden}
                        mediHiddenOnChange={setMediHidden}
                        cctHiddenOnChange={setCctHidden}
                        cbChartArea={setChartArea}
                        useTimebar={true}
                        boxsize={box4Size}
                    /> 
                }
                {getGraphView === 1 && 
                    <DeviceSpectrum                     
                        ts={tsRef.current} 
                        spdData={spdDataFilteredRef.current}
                        cbChartArea={setChartArea}
                        useTimebar={true}
                        boxsize={box4Size}
                    /> 
                }

            </div>         

            {getShowMetaModal && 
                <MetaModal 
                    onHide={() => {setShowMetaModal(false);}} 
                    deviceIdArray={[deviceIdRef.current]} 
                    metaDisplay=""
                />
            }

            {getShowDownloadModal && 
                <DownloadModal 
                    onHide={() => {setShowDownloadModal(false);}} 
                    sensorName={helperCtx.translateId(deviceIdRef.current)} 
                    deviceId={deviceIdRef.current}
                    dateStart={downloadStartRef.current}
                    dateEnd={downloadEndRef.current}
                />
            }

        </div>
    );
}
